import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import loginbg from "../images/logo.svg";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { Formik } from "formik";
import { login } from "../redux/actions/adminAuth";

export default function Login() {
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);

  const dispatch = useDispatch();
  const cookies = new Cookies();

  useEffect(() => {
    if (cookies.get("token") || sessionStorage.getItem("token")) {
      sessionStorage.setItem("token", cookies.get("token"));
      window.location.href = "/admin/dashboard";
    }
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="vh-100">
          <Col
            className="bg-gray d-flex align-items-center justify-content-center"
            lg={6}
          >
            <div className="sign-up-card p-3">
              {" "}
              <Image src={loginbg} alt="login image" fluid />
            </div>
          </Col>
          <Col
            lg={6}
            className="d-flex align-items-center justify-content-center login-bg"
          >
            <div className=" border-0 sign-up-card">
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2">Sign In </h2>
                <p className="mb-4">Please enter your login and password!</p>
                <div className="">
                  <Formik
                    initialValues={{ email: "", password: "" }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = "Please enter email";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }

                      if (!values.password) {
                        errors.password = "Please enter password";
                      } else if (values.password < 8) {
                        errors.password = "Password must be of 8 characters";
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        dispatch(login({ loginObj: values, keepMeLoggedIn }));

                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="text-center">
                            Email address
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            size="lg"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <span className="formik-errors">
                            {errors.email && touched.email && errors.email}
                          </span>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            size="lg"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <span className="formik-errors">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </span>
                        </Form.Group>

                        {/* <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Check
                            onChange={(e) =>
                              setKeepMeLoggedIn(e.target.checked)
                            }
                            type="checkbox"
                            label="Remember me"
                            checked={keepMeLoggedIn}
                          />
                        </Form.Group> */}
                        <div className="d-grid">
                          <Button
                            disabled={isSubmitting}
                            className="w-100 mt-3"
                            variant="primary"
                            type="submit"
                            size="lg"
                          >
                            Sign In
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
