import { useScrollTrigger } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shareLink } from "../redux/actions/adminActions";
import { useParams } from "react-router-dom";
import dummy from "../images/dummy_img.png";

export default function ProfileView() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const alldata = useSelector((state) => state.courseSlice.courses);
  console.log(alldata);

  useEffect(() => {
    dispatch(shareLink(id));
  }, []);

  return (
    <div>
      <div className="container">
        <div className="spotlift-profile-main">
          <div className="row">
            <div className="col-lg-12">
              <div className="profile-pic">
                <img
                  src={
                    alldata?.profile_image
                      ? BASE_URL + "/" + alldata?.profile_image
                      : dummy
                  }
                />
                <div className="profile-desc">
                  <h2>
                    {alldata?.full_name}, {alldata?.age}{" "}
                    <span>
                      <img src={require("../images/green.svg").default} />
                    </span>
                  </h2>

                  {/* <p>{alldata?.displacement}</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="btm-desc">
                <h3>About me</h3>
                <p>{alldata?.bio}</p>
              </div>
            </div>
            <hr />
            <div className="col-lg-12">
              <div className="gender-select">
                <img src={require("../images/user.svg").default} />
                <p>
                  {alldata?.gender == 0
                    ? "Female"
                    : alldata?.gender == 1
                    ? "Male"
                    : "Other"}
                </p>
              </div>
            </div>
            <hr />
            <div className="col-lg-12">
              <div className="looking-sec">
                <h2>Looking for</h2>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="looking-images">
                      <img src={require("../images/lift.png")} />
                      <h3>
                        {alldata?.lookingFor
                          ? alldata?.lookingFor == 0
                            ? "Female"
                            : alldata?.lookingFor == 1
                            ? "Male"
                            : alldata?.lookingFor == 3 && "Prefer not to say"
                          : "N/A"}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="col-lg-12">
              {" "}
              <div className="interest-sec">
                <h2>Dietary Interests</h2>
                <div className="interest-images">
                  <ul>
                    {alldata?.dietary_interest?.map((x) => {
                      return <li>{x?.name}</li>;
                    }) || "N/A"}
                    {/* <li>Vegetarian</li>
                    <li>Paleo</li> */}
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div className="col-lg-12">
              <div className="lift-stats">
                <h2>Lifting Stats:</h2>
                <ul>
                  <li>{alldata?.lifter_type || "N/A"}</li>
                </ul>

                <h2>My best lifts</h2>

                <ul>
                  <li>
                    <div className="best-lift">
                      <h3>{alldata?.excercise1}</h3>
                      <p>{alldata?.excercise_value1}</p>
                    </div>
                  </li>
                  <li>
                    <div className="best-lift">
                      <h3>{alldata?.excercise2}</h3>
                      <p>{alldata?.excercise_value2}</p>
                    </div>
                  </li>
                  <li>
                    <div className="best-lift">
                      <h3>{alldata?.excercise2}</h3>
                      <p>{alldata?.excercise_value3}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div className="col-lg-12">
              <h2>
                <h2>Current Gym</h2>
              </h2>
              <div className="current-sec">
                <ul>
                  <li>
                    <div className="gym-inner">
                      <img
                        src={
                          alldata?.current_gym_img
                            ? alldata?.current_gym_img
                            : dummy
                        }
                      />
                      <div>
                        <h2>{alldata?.current_gym_name}</h2>
                        <p>{alldata?.current_gym_address}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div className="col-lg-12">
              <div className="gallery-images">
                <h3>Images</h3>
                <div className="row">
                  {alldata?.post?.map((x) => {
                    return (
                      <div className="col-lg-4 mt-4">
                        <img
                          src={
                            x?.image
                              ? BASE_URL + "/" + x?.image
                              : dummy
                          }
                        />
                      </div>
                    );
                  })}

                  {/* <div className="col-lg-4 mt-4">
                    <img src={require("../images/Rectangle 281.png")} />
                  </div>
                  <div className="col-lg-4 mt-4">
                    <img src={require("../images/Rectangle 282.png")} />
                  </div>
                  <div className="col-lg-4 mt-4">
                    <img src={require("../images/Rectangle 283.png")} />
                  </div>
                  <div className="col-lg-4 mt-4">
                    <img src={require("../images/Rectangle 285.png")} />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
