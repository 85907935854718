import React from "react"
import Layout from "../components/Layout/Layout"
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap"
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { changePass } from "../redux/actions/adminAuth";


export default function ChangePassword() {

    const dispatch = useDispatch();

    return (
        <>
            <Layout>
                <Container fluid>
                    <Row className="justify-content-md-center">
                        <Col lg="6" md="auto">
                            <Card className="border-0 p-5 rounded-card mt-5">
                                <h2 className="text-center">Change Password</h2>
                                <Formik
                                    initialValues={{old_password:"", new_password: "", confirm_password: "" }}
                                    validate={(values) => {
                                        const errors = {};
                                        if (!values.old_password) {
                                            errors.old_password = "Old Password is Required";
                                        } 
                                        if (!values.new_password) {
                                            errors.new_password = "New Password is Required";
                                        } 
                                        if (!values.confirm_password) {
                                            errors.confirm_password = "Please Confirm the Password";
                                        }else if (values.new_password != values.confirm_password) {
                                            errors.confirm_password = "Password Doesn't match";
                                        }

                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        console.log(values,"values")
                                        if (values.new_password == values.confirm_password) {
                                            dispatch(changePass({ newpassword: values?.confirm_password }));
                                        }

                                        setSubmitting(false);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (
                                        <Form onSubmit={handleSubmit} className="change-password-form px-5 mt-5">
                                            
                                            <Row className="mb-4">
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label>Old Password</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="Old Password"
                                                        name="old_password"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.old_password}
                                                    />
                                                    <span className="formik-errors">
                                                        {errors.old_password && touched.old_password && errors.old_password}
                                                    </span>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-4">
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label>New Password</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="New Password"
                                                        name="new_password"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.new_password}
                                                    />
                                                    <span className="formik-errors">
                                                        {errors.new_password && touched.new_password && errors.new_password}
                                                    </span>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-4">
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label>Confirm Password</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="Confirm Password"
                                                        name="confirm_password"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.confirm_password}
                                                    />
                                                    <span className="formik-errors">
                                                        {errors.confirm_password && touched.confirm_password && errors.confirm_password}
                                                    </span>
                                                </Form.Group>
                                            </Row>
                                            <Form.Group className="mt-5  mb-5 model-btn text-center" controlId="formGridAddress2">
                                                <Button type="submit" variant="primary" size="lg" className="add-btn-shadow">
                                                    Submit
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    )}
                                </Formik>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    )
}