import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { changePass } from "../redux/actions/adminAuth";
import { adminchatmessages, chatmessages } from "../redux/actions/adminActions";
import { Link, useLocation } from "react-router-dom";
import { CleaningServices } from "@mui/icons-material";
import { Message } from "semantic-ui-react";
import Profile from "../images/Photo_profile.svg";

export default function SupportChat() {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const location = useLocation();
  const data = useSelector((state) => state.supportSlice.messages);
  const admindata = useSelector((state) => state.supportSlice.adminmessages);
  console.log(data);
  const [message, setMessage] = useState("");
  console.log(location, "jhsgdf");
  const [flag, setflag] = useState(true);
  // const messageInputRef = useRef(null);
  const chatContainerRef = useRef(null);

  console.log(chatContainerRef, "darda");

  useEffect(() => {
    dispatch(chatmessages(location?.state?.id));
  }, [flag]);

  const handleclick = (e) => {
    e.preventDefault();

    dispatch(
      adminchatmessages({
        message: message,
        support_id: location?.state?.id,
        sender_type: "",
      })
    );
    setMessage("");
    setflag(!flag);
    // if (messageInputRef.current) {
    //   // Do something with the input element
    //   messageInputRef.current.focus(); // For example, focusing on the input element
    // }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat area
    console.log("first");
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [data]);

  return (
    <>
      <Layout>
        <Row>
          <Col lg={12}>
            <div className="mb-4">
              <Link to="/admin/support">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M3 7.99935L1.93934 9.06001L0.87868 7.99935L1.93934 6.93869L3 7.99935ZM23 6.49935C23.8284 6.49935 24.5 7.17092 24.5 7.99935C24.5 8.82778 23.8284 9.49935 23 9.49935V6.49935ZM8.60601 15.7267L1.93934 9.06001L4.06066 6.93869L10.7273 13.6054L8.60601 15.7267ZM1.93934 6.93869L8.60601 0.272022L10.7273 2.39334L4.06066 9.06001L1.93934 6.93869ZM3 6.49935L23 6.49935V9.49935L3 9.49935L3 6.49935Z"
                    fill="#40413A"
                  />
                </svg>
              </Link>
            </div>
          </Col>
        </Row>
        <Container fluid >
          <Row className="justify-content-md-center">
            <Container>
              <Row className="justify-content-center">
                <Col lg={9}>
                  <div className="support-chat-box-main">
                    <div className="support-inner">
                      <h2>User Support</h2>
                      <div  fluid ref={chatContainerRef} className="support-msg-box">
                        {data?.data?.chats?.map((x) => {
                          if (x?.docModel == "User") {
                            return (
                              <div className="user-box left-chat-part">
                                <div className="user-top-hdng">
                                  <img
                                    src={
                                      data?.data?.userdetails?.userimage
                                        ? BASE_URL +
                                          "/" +
                                          data?.data?.userdetails?.userimage
                                        : null
                                    }
                                  />
                                  <h3>
                                    {data?.data?.userdetails?.username || "N/A"}
                                  </h3>
                                </div>

                                <div className="msg-w-tym">
                                  <div className="user-msg-box">
                                    <p>{x?.message}</p>
                                  </div>
                                  <span>
                                    {x?.updatedAt
                                      ? new Date(
                                          x?.updatedAt
                                        ).toLocaleTimeString()
                                      : null}
                                  </span>
                                </div>
                              </div>
                            );
                          } else if (x?.docModel == "Admin") {
                            return (
                              <div className="user-box right-chat-part">
                                <div className="user-top-hdng">
                                  <h3>You</h3>
                                  <img src={Profile} />
                                </div>

                                <div className="right-msg-text">
                                  <div className="msg-w-tym">
                                    <div className="user-msg-box">
                                      <p>{x?.message}</p>
                                    </div>
                                    <span>
                                      {x?.updatedAt
                                        ? new Date(
                                            x?.updatedAt
                                          ).toLocaleTimeString()
                                        : null}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                   
                      <form>
                        <div class="send-box">
                          <div class="mb-3 input-group">
                            <input
                              placeholder="Type your message here..."
                              aria-label="send"
                              aria-describedby="basic-addon2"
                              name="message"
                              type="text"
                              class="send-feild form-control"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                            <button
                              class="input-group-text"
                              id="basic-addon2"
                              onClick={handleclick}
                            >
                              <img
                                src={require("../images/send-icon.svg").default}
                              />
                            </button>
                          </div>
                        </div>
                      </form>
                   
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
