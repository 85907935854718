import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Container,
  Row,
  Card,
  ListGroup,
  Image,
  Button,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import Deleteicon from "../images/delete_icon.svg";
import Editicon from "../images/edit_icon.svg";
import Playicon from "../images/play_icon.svg";
import playpop from "../images/plypop.png";
import { FiPlus } from "react-icons/fi";

export default function QuestionDetails() {
  const [showAudio, setShowAudio] = useState(false);

  const handleClose = () => setShowAudio(false);
  const handleShow = () => setShowAudio(true);

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <Card className="border-0 card-user-detail rounded-card meditation-info px-4 mt-5">
              <div className="top-flex justify-content-between mb-5">
                <div>
                  <h2>Question Details</h2>
                </div>
                <div>
                  {/* <Button
                    className="add-category-btn new-self-ref"
                    variant="primary"
                    onClick={handleShow}
                  >
                    <FiPlus /> Add New Self reflection
                  </Button> */}
                </div>
              </div>
              <Container>
                <Row>
                  <Card className="border-0 p-4 rounded-card self-reflection-body question-details-card mt-5 mb-5">
                    <ListGroup
                      as="ol"
                      numbered
                      className="theory-list pt-2 pb-5"
                    >
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between list-card-play"
                      >
                        <div className="ms-2 me-auto d-flex align-items-center">
                          <div className="list-title">Audio File</div>
                        </div>
                        <div className="icons-list">
                          <Image
                            src={Playicon}
                            className="img-play-card custom-cursor-pointer"
                            onClick={handleShow}
                          />
                        </div>
                      </ListGroup.Item>
                      <Image src={Editicon} className="custom-cursor-pointer"/>
                    </ListGroup>
                    <div className="ques-details mb-3">
                      <h4>Exercise</h4>
                      <p>Questionnaire</p>
                    </div>
                    <div className="ques-details mb-3">
                      <h4>Answer Types</h4>
                      <p>MCQ</p>
                    </div>
                    <div className="ques-details mb-3">
                      <h4>Question</h4>
                      <p>
                        Q1-Which of my past successes and failures have
                        influenced me the most, and what have I learned from
                        them?
                      </p>
                    </div>

                    <Form.Group className="">
                      <div class="custom_check radio">
                        <ul>
                          <li class="list-item">
                            <input
                              type="radio"
                              class="hidden-box top0"
                              id="option1"
                              name="interestedIn"
                              value="1"
                            />
                            <label for="option1" class="check--label">
                              A<span class="check--label-box">A</span>
                              <span class="check--label-text">Lorem Ipsum</span>
                            </label>
                          </li>
                          <li class="list-item">
                            <input
                              type="radio"
                              class="hidden-box top0"
                              id="option2"
                              name="interestedIn"
                              value="0"
                            />
                            <label for="option2" class="check--label">
                              B<span class="check--label-box">B</span>
                              <span class="check--label-text">Lorem Ipsum</span>
                            </label>
                          </li>
                          <li class="list-item">
                            <input
                              type="radio"
                              class="hidden-box top0"
                              id="option3"
                              name="interestedIn"
                              value="2"
                            />
                            <label for="option3" class="check--label">
                              C<span class="check--label-box">C</span>
                              <span class="check--label-text">
                                Lorem Ipsume
                              </span>
                            </label>
                          </li>
                          <li class="list-item">
                            <input
                              type="radio"
                              class="hidden-box top0"
                              id="option4"
                              name="interestedIn"
                              value="2"
                            />
                            <label for="option4" class="check--label">
                              D<span class="check--label-box">D</span>
                              <span class="check--label-text">
                                Lorem Ipsume
                              </span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </Form.Group>

                    <div class="custom_check custom_check_details radio ">
                      <div className="upld-audio-title">Answer</div>
                      <ul>
                        <li class="list-item">
                          <input
                            type="radio"
                            class="hidden-box top0"
                            id="option5"
                            name="interestedIn"
                            value="1"
                          />
                          <label for="option5" class="check--label ">
                            A<span class="check--label-box">A</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </Card>
                </Row>
              </Container>
            </Card>
          </Row>
        </Container>
      </Layout>

      {/* Play Model */}
      {/* <Modal show={showAudio} onHide={handleClose} centered>
        <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
        <Modal.Body className="text-center border-0">
          <Modal.Title className="mb-5">D-Stress</Modal.Title>
          <Image src={playpop} width="35%" />
          <audio controls className="mb-4 mt-5">
            <source
              src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
              type="audio/wav"
            />
          </audio>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
