import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Row,
  Badge,
  Button,
  Form,
  Table,
  Stack,
  Modal,
} from "react-bootstrap";
import { FiArrowDown, FiSearch } from "react-icons/fi";
import Layout from "../components/Layout/Layout";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  VerifiedUsers,
  getCoaches,
  getVerificationUsers,
  verifyCoach,
} from "../redux/actions/adminActions";
import dummy from "../images/dummy_img.png";
import Pagination from "../components/Pagination";

export default function UserVerifications() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.userVerifySlice.users);
  console.log(users);

  // const totalCoaches = useSelector((state) => state.coachSlice.totalCoaches);
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [search, setSearch] = useState("");
  // const [searchData, setSearchData] = useState("");
  const [pages, setPages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [show, setshow] = useState(false);
  const [showd, setshowd] = useState(false);
  const [flag, setflag] = useState(false);

  const [Id, setId] = useState(null);

  useEffect(() => {
    dispatch(getVerificationUsers({ pages, limit, search }));
  }, [pages, search, flag]);

  const handleaccept = (id, e) => {
    dispatch(
      VerifiedUsers({
        userId: id,
        admin_verified: 1,
        type: "verification",

      })
    ).then((res) => {
      if (res?.payload?.success) {
        setshow(false);
        setflag(!flag);
      }
    });
  };
  const handlereject = (id, e) => {
    dispatch(
      VerifiedUsers({
        userId: id,
        type: "reject",
        admin_verified: 0,
      })
    ).then((res) => {
      if (res?.payload?.success) {
        setshow(false);
        setflag(!flag);
      }
    });
  };

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <Stack className="mb-3 mt-4" direction="horizontal" gap={1}>
              {/* <div className="p-2 ms-auto"><Button className="text-primary-clr" size="lg" variant=""><FiFilter/></Button>{' '}</div> */}
              {/* <div className="p-2 ms-auto">
                <Button
                  className="text-primary-clr align-item-center table-head-btn"
                  size="lg"
                  variant="light"
                >
                  <FiArrowDown /> Download
                </Button>{" "}
              </div> */}
            </Stack>
            <Card className="border-0 p-4 rounded-card user-section ">
              <Stack
                className="user-table-align table-head"
                direction="horizontal"
                gap={3}
              >
                <div className="p-2">
                  <h3>User Verifications</h3>
                </div>
                <div className="p-2 ms-auto">
                  <div className="position-relative m-2 ">
                    <Form.Control
                      type="search"
                      placeholder="Search.."
                      className="serch-feild"
                      onChange={(e) => setSearch(e.target.value)}
                      // onKeyDown={handleEnter}
                    />
                    {!search && <FiSearch className="searchicon2" />}
                  </div>
                </div>
              </Stack>
              <Table
                responsive
                variant=""
                className="border-0 custom-table coaches-table mt-3 table-btn"
              >
                <thead className="first-td">
                  <tr>
                    <th>S.No</th>
                    <th>Full Name</th>
                    <th>Email </th>
                    <th>Phone Number</th>
                    <th>Profile image</th>
                    <th>Gender</th>
                    <th colSpan={3}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users?.data?.users?.map((x, i) => {
                    const seralnumber = (pages - 1) * limit + i + 1;
                    return (
                      <tr>
                        <td>{seralnumber}</td>
                        <td>{x?.full_name || "N/A"}</td>
                        <td>{x?.email || "N/A"}</td>
                        <td>{x?.phone_number || "N/A"}</td>
                        <td>
                          <img
                            src={
                              x?.profile_image
                                ? BASE_URL + "/" + x?.profile_image
                                : dummy
                            }
                            alt="user image"
                            className="rounded-circle profile-image"
                          />
                        </td>
                        <td>
                          {x?.gender === 0
                            ? "Female"
                            : x?.gender === 1
                            ? "Male"
                            : x?.gender === 2
                            ? "Other"
                            : "N/A"}
                        </td>

                        <td>
                          <div className="table-btn hstack gap-3">
                            <Button
                              name="Acceptvalue"
                              value="1"
                              onClick={(e) => handleaccept(x._id, e)}
                              as="a"
                              variant="primary"
                            >
                              {x?.admin_verified === 1 ? "Accepted" : "Accept"}
                            </Button>
                            <Button
                              value="0"
                              onClick={(e) => handlereject(x._id, e)}
                              as="a"
                              variant="success"
                            >
                              {x?.admin_verified === 0 ? "Rejected" : "Reject"}
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                  {/* {coaches?.length > 0 &&
                    coaches?.map((coach, i) => {
                      return (
                        <tr key={coach?._id}>
                          <td>{i + 1}</td>
                          <td>{coach?.full_name}</td>
                          <td>
                            {coach?.gender == 0
                              ? "Female"
                              : coach?.gender == 1
                              ? "Male"
                              : coach?.gender == 3 && "Prefer not to say"}
                          </td>
                          <td>{coach?.address}</td>
                          <td>${coach?.rateperhour}</td>
                          <td>
                            <Badge bg={statusObj[coach?.admin_verified]?.css}>
                              {statusObj[coach?.admin_verified]?.text}
                            </Badge>
                          </td>
                          <td>
                            <Stack
                              direction="horizontal"
                              className="table-btn"
                              gap={3}
                            >
                              {coach?.admin_verified == 0 && (
                                <Button
                                  onClick={() => handleVerify(coach?._id, 1)}
                                  variant="primary"
                                >
                                  Accept
                                </Button>
                              )}
                              {coach?.admin_verified == 0 && (
                                <Button
                                  onClick={() => handleVerify(coach?._id, 2)}
                                  variant="danger"
                                >
                                  Reject
                                </Button>
                              )}
                              <Link
                                to={`/admin/coachdetail/${coach?._id}`}
                                className="text-light"
                              >
                                <Button variant="light">View</Button>
                              </Link>
                            </Stack>
                          </td>
                        </tr>
                      );
                    })} */}
                </tbody>
              </Table>
              {users?.data?.users?.length == 0 && (
                <div className="no-data-updated">
                  <p className="no-data">No data updated yet</p>
                </div>
              )}
            </Card>
          </Row>
        </Container>

        <Pagination
          totalstuff={users?.data?.total}
          limit={users?.data?.limit}
          setpages={setPages}
          search={search}
        />

        {/* <Modal show={show} animation={false}>
          <Modal.Body>
            <p className="modal_head">
              Are you sure you want to verify this account?
            </p>
          </Modal.Body>
          <Modal.Footer className="m-auto">
            <Button variant="secondary" onClick={handlAccept}>
              Yes
            </Button>
            <Button variant="primary" onClick={() => setshow(false)}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showd} animation={false}>
          <Modal.Body>
            <p className="modal_head">
              Are you sure you want to verify this account?
            </p>
          </Modal.Body>
          <Modal.Footer className="m-auto">
            <Button variant="secondary" onClick={handlReject}>
              Yes
            </Button>
            <Button variant="primary" onClick={() => setshowd(false)}>
              No
            </Button>
          </Modal.Footer>
        </Modal> */}
      </Layout>
    </>
  );
}
