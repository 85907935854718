import React, { useEffect } from "react";
import Layout from "../Layout/Layout";
import { Card, Col, Container, Row, Figure, ListGroup } from "react-bootstrap";
import Profile from "../../images/single-profile.png";
import { FiMapPin, FiUser, FiArrowLeft, FiMail } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCoachDetails } from "../../redux/actions/adminActions";
import dummyimg from "../../images/dummy_img.png";

export default function CoachDetail() {
  const FILE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const coach = useSelector((state) => state.coachSlice.coachDetails);
  const availability = useSelector((state) => state.coachSlice.availability);

  useEffect(() => {
    dispatch(getCoachDetails(id));
  }, []);

  return (
    <>
      <Layout>
        <div className="backBtn">
          <Link onClick={() => navigate(-1)}>
            <h5 className="text-primary-clr backpad">
              <FiArrowLeft /> Back
            </h5>
          </Link>
        </div>
        <Container>
          <Row>
            <Col lg="12">
              <Card className="border-0 card-user-detail rounded-card mb-5">
                <Row>
                  <Figure className="user-info mt-3">
                    <Figure.Image
                      src={
                        coach?.profile_image
                          ? FILE_URL + coach?.profile_image
                          : dummyimg
                      }
                      className="border-0 profile-img"
                      roundedCircle
                    />
                    <Figure.Caption className="px-5 text-dark">
                      <h3 className="fw-bold mb-4">{coach?.full_name}</h3>
                      <h6 className="mb-3">
                        <span className="dslpnonmob">
                          <FiMapPin /> {coach?.address}
                        </span>
                      </h6>
                      <h6 className="mb-3">
                        <span className="dslpnonmob">
                          <FiUser />{" "}
                          {coach?.gender
                            ? coach?.gender == 0
                              ? "Female"
                              : coach?.gender == 1
                              ? "Male"
                              : coach?.gender == 3 && "Prefer not to say"
                            : "N/A"}
                        </span>
                      </h6>
                      <h6 className="mb-3">
                        <span className="dslpnonmob">
                          <FiMail /> {coach?.email}
                        </span>
                      </h6>
                    </Figure.Caption>
                    <div className="rate-box text-center">
                      <h6>Rate</h6>
                      <h2>${parseFloat(coach?.rateperhour).toFixed(2)}</h2>
                    </div>
                  </Figure>
                </Row>

                <Row className="pt-4">
                  <Col lg="12" className="">
                    <h5 className="mb-3">Biography</h5>
                    <p>{coach?.coachBio}</p>
                  </Col>
                </Row>

                <Row>
                  {availability?.length > 0 &&
                    availability?.map((av) => {
                      return (
                        <Col lg="6" className="">
                          <div className="pt-5">
                            <h6 className="mb-2 text-primary-clr">{av?.day}</h6>
                          </div>
                          <ListGroup>
                            {av?.timeArr?.map((time) => (
                              <ListGroup.Item>
                                {time?.opening} - {time?.closing}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </Col>
                      );
                    })}
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
