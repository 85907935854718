import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { adminchatmessages, changeSupportStatus, chatmessages, getSupports } from "../actions/adminActions";

const initialState = {
  supports: null,
  messages:null,
  adminmessages:null
};

export const supportSlice = createSlice({
  name: "supportsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSupports.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.supports = payload;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(chatmessages.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.messages = payload;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(adminchatmessages.fulfilled, (state, { payload }) => {
        // console.log(payload)
        if (payload.success) {
          state.adminmessages = payload;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(changeSupportStatus.fulfilled, (state, { payload }) => {
        if (payload.success) {
          // state.supports = state.supports?.map((sup) =>
          //   sup?._id == payload?.data?._id ? payload?.data : sup
          // );
          toast.success(payload.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default supportSlice.reducer;
