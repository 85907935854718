import React, { useState } from "react";
import { Menu, MenuItem } from "react-pro-sidebar";
import {
  FiUsers,
  FiAlertOctagon,
  FiGrid,
  FiChevronLeft,
  FiChevronRight,
  FiSliders,
  FiUserCheck,
  FiFlag,
  FiBell,
} from "react-icons/fi";
import logo_main from "../../images/logo.svg";
import Footer from "../Layout/Footer";
import { Link, useLocation } from "react-router-dom";

const Sidebar = ({ setCssclass }) => {
  const [menuCollapse, setMenuCollapse] = useState(false);

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    setCssclass(menuCollapse ? "" : "wrap-content");
  };

  const { pathname } = useLocation();

  const isCurrentPage = (tab) => {
    return pathname.includes(tab);
  };

  return (
    <>
      <div id="header" className={` vh-100 ${menuCollapse ? "collapsed" : ""}`}>
        <div className="pro-sidebar">
          <div className="SidebarHeader">
            <div className="logotext px-3 py-4">
              <img src={menuCollapse ? logo_main : logo_main} alt="logo"></img>
            </div>
            <div className="closemenu" onClick={menuIconClick}>
              {menuCollapse ? (
                <FiChevronRight className="side-icon" />
              ) : (
                <FiChevronLeft className="side-icon" />
              )}
            </div>
          </div>
          <div className="SidebarContent">
            <div className="text-muted mx-4 mb-3">MENU</div>
            <div className="bg-light mx-4 rounded-3">
              <Menu iconShape="square">
                <Link to="/admin/dashboard">
                  <MenuItem
                    active={isCurrentPage("dashboard")}
                    icon={<FiGrid />}
                  >
                    Dashboard
                  </MenuItem>
                </Link>
                <Link to="/admin/users">
                  <MenuItem active={isCurrentPage("users")} icon={<FiUsers />}>
                    User Management
                  </MenuItem>
                </Link>
                <Link to="/admin/user-verifications">
                  <MenuItem
                    active={isCurrentPage("user-verifications")}
                    icon={<FiUserCheck />}
                  >
                    User Verifications
                  </MenuItem>
                </Link>

                <Link to="/admin/excercises">
                  <MenuItem
                    active={isCurrentPage("excercises")}
                    icon={<FiFlag />}
                  >
                    Exercises
                  </MenuItem>
                </Link>
                <Link to="/admin/support">
                  <MenuItem
                    active={isCurrentPage("support")}
                    icon={<FiAlertOctagon />}
                  >
                    Support
                  </MenuItem>
                </Link>
                <Link to="/admin/report">
                  <MenuItem
                    active={isCurrentPage("report")}
                    icon={<FiAlertOctagon />}
                  >
                    Report
                  </MenuItem>
                </Link>
                <Link to="/admin/notification">
                  <MenuItem
                    active={isCurrentPage("notification")}
                    icon={<FiBell />}
                  >
                    Notification
                  </MenuItem>
                </Link>




              
              </Menu>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Sidebar;
