import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Container,
  Row,
  Card,
  ListGroup,
  Image,
  Button,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Deleteicon from "../images/delete_icon.svg";
import Editicon from "../images/edit_icon.svg";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReflection,
  getReflections,
} from "../redux/actions/adminActions";

export default function SelfReflection() {
  const [showAudio, setShowAudio] = useState(false);
  const [delId, setDelId] = useState(false);
  const [showDel, setShowDel] = useState(false);

  const reflections = useSelector((state) => state.reflectionSlice.reflections);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getReflections());
  }, []);

  const handleDeleteRef = () => {
    dispatch(deleteReflection(delId));
    setShowDel(false);
  };

  const exercise = {
    0: "Questionnaire",
    1: "Story Timeline",
    2: "Wheel of Life",
  };

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <Card className="border-0 card-user-detail rounded-card meditation-info px-4 mt-4 mb-5">
              <div className="top-flex justify-content-between mb-5">
                <div>
                  <h2>Self Reflection</h2>
                </div>
                <div>
                  <Link to="/admin/add-new-reflection">
                    <Button
                      className="add-category-btn new-self-ref"
                      variant="primary"
                    >
                      <FiPlus /> Add New Self reflection
                    </Button>
                  </Link>
                </div>
              </div>
              <Container>
                <Row>
                  <Card className="border-0 p-4 rounded-card self-reflection-body self-reflection-main mt-5 mb-5">
                    <ListGroup
                      as="ol"
                      numbered
                      className="theory-list pt-5 pb-5"
                    >
                      {reflections?.length > 0 &&
                        reflections?.map((ref, i) => {
                          return (
                            <ListGroup.Item
                              key={ref?._id}
                              as="li"
                              className="d-flex justify-content-between list-card-play"
                            >
                              <div className="ms-2 d-flex align-items-center width-70-card">
                                <div className="icon-number">{i + 1}</div>
                                <div className="list-title">
                                  {`${ref?.title}(${ref?.subTitle})`}
                                  <p>Exercise : {exercise[ref?.journeytype]}</p>
                                </div>
                              </div>
                              <div className="icons-list width-30-card">
                                <Image
                                  onClick={() => navigate(`/admin/editSelfReflection/${ref?._id}`)}
                                  className="cursor-pointer"
                                  src={Editicon}
                                />
                                <Image
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setDelId(ref?._id);
                                    setShowDel(true);
                                  }}
                                  src={Deleteicon}
                                />
                              </div>
                            </ListGroup.Item>
                          );
                        })}
                    </ListGroup>
                  </Card>
                </Row>
              </Container>
            </Card>
          </Row>
        </Container>
      </Layout>

      {/* Delete Modal */}

      {delId && (
        <Modal
          show={showDel}
          onHide={() => setShowDel(false)}
          className="text-center meditation-model modal-content-80"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
          <Modal.Title className="mb-4 px-3">
            Delete Self Reflection
          </Modal.Title>
          <Modal.Body>
            <p>Are you sure you want to delete this Self Reflection?</p>
          </Modal.Body>

          <Modal.Footer className="m-auto border-0">
            <Button
              className="mb-5"
              variant="primary"
              size="lg"
              onClick={handleDeleteRef}
            >
              Yes
            </Button>
            <Button
              className="mb-5"
              variant="primary"
              size="lg"
              onClick={() => setShowDel(false)}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Play Model */}
      {/* <Modal show={showAudio} onHide={handleClose} centered>
        <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
        <Modal.Body className="text-center border-0">
          <Modal.Title className="mb-5">D-Stress</Modal.Title>
          <Image src={playpop} width="35%" />
          <audio controls className="mb-4 mt-5">
            <source
              src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
              type="audio/wav"
            />
          </audio>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
