import { configureStore } from "@reduxjs/toolkit";
import userMgmtReducer from "./reducers/userMgmtSlice";
import userVerifyReducer from "./reducers/userVerifySlice";

import notificationReducer from "./reducers/notificationSlice";
import supportReducer from "./reducers/supportSlice";
import reReducer from "./reducers/reSLice"
import reflectionReducer from "./reducers/reflectionSlice";
import meditationReducer from "./reducers/meditationSlice";
import courseReducer from "./reducers/courseSlice";
import dashboardReducer from "./reducers/dashboardSlice";
import { exerciseSlice } from "./reducers/ExerciseSlice";

export const store = configureStore({
  reducer: {
    userMgmtSlice: userMgmtReducer,
    userVerifySlice: userVerifyReducer,
    reSlice: reReducer,
    // reportsdata:reportsSlice.reducer,
    notificationSlice: notificationReducer,
    supportSlice: supportReducer,
    exercisedata: exerciseSlice.reducer,
    reflectionSlice: reflectionReducer,
    meditationSlice: meditationReducer,
    courseSlice: courseReducer,
    dashboardSlice: dashboardReducer,
  },
});

// reportsSlice: reportReducer,
// console.log(reportsSlice.reports)
