

import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getReport } from "../actions/adminActions";

const initialState = {
  reports: null,
  
};

export const reSlice = createSlice({
  name: "reflectionSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReport.fulfilled, (state, { payload }) => {
        // console.log(action)
        console.log(payload)
        if (payload?.success) {
          state.reports = payload;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
     
     
  },
});

export default reSlice.reducer;