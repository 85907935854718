import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createCourseCategory,
  deleteCourseCategory,
  getCourseCategories,
} from "../actions/adminActions";
const initialState = {
  courseCats: null,
};

export const meditationSlice = createSlice({
  name: "meditationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourseCategories.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.courseCats = payload.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(createCourseCategory.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.courseCats.push(payload.data);
          toast.success(payload?.message)
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteCourseCategory.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.courseCats = state.courseCats?.filter(
            (cat) => cat?._id != payload.data._id
          );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default meditationSlice.reducer;
