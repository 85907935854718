import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../services/adminApi";
import { toast } from "react-toastify";
import { create } from "@mui/material/styles/createTransitions";

const dashboard = createAsyncThunk("dashboard", async () => {
  const { data } = await adminApi.get("/dashboard");
  return data;
});

const getUsers = createAsyncThunk(
  "getUsers",
  async ({ pages, limit, search }) => {
    const { data } = await adminApi.get(
      `/getAllUsers?page=${pages}&limit=${limit}&search=${search}`
    );
    return data;
  }
);
const getallUsers = createAsyncThunk("getallUsers", async () => {
  const { data } = await adminApi.get(`/getUsers`);
  return data;
});

const getUserDetails = createAsyncThunk("getUserDetails", async (id) => {
  const { data } = await adminApi.get(`/getUserDetails/?user_id=${id}`);
  return data;
});

const editUserDetails = createAsyncThunk(
  "editUserDetails",
  async (formdata) => {
    const { data } = await adminApi.put("/editUserDetails", formdata);
    return data;
  }
);

const changeUserPass = createAsyncThunk("changeUserPass", async (passObj) => {
  const { data } = await adminApi.put("/changeUserPass", passObj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const deactivateUser = createAsyncThunk("deactivateUser", async (deObj) => {
  const { data } = await adminApi.put("/deactivateUserAccount", deObj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const deleteUser = createAsyncThunk("deleteUser", async (id) => {
  const { data } = await adminApi.delete(`/deleteUserAccount?user_id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const getCoaches = createAsyncThunk(
  "getCoaches",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getCoaches?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const verifyCoach = createAsyncThunk("verifyCoach", async (verifyObj) => {
  const { data } = await adminApi.post("/verifyCoach", verifyObj);
  return data;
});

const getCoachDetails = createAsyncThunk("getCoachDetails", async (id) => {
  const { data } = await adminApi.get(`/getCoachDetails?id=${id}`);
  return data;
});

const getVerificationUsers = createAsyncThunk(
  "getVerificationUsers",
  async ({ pages, limit, search }) => {
    const { data } = await adminApi.get(
      `/getVerificationUsers?page=${pages}&limit=${limit}&search=${search}`
    );
    return data;
  }
);
const VerifiedUsers = createAsyncThunk("VerifiedUsers", async (details) => {
  const { data } = await adminApi.post(`/verifyUser`, details);
  return data;
});

const getReports = createAsyncThunk("getReports", async () => {
  const { data } = await adminApi.get("/getReports");
  return data;
});

const deleteReport = createAsyncThunk("deleteReport", async (id) => {
  const { data } = await adminApi.delete(`/deleteReport?id=${id}`);
  return data;
});
const closeReport = createAsyncThunk("closeReport", async (details) => {
  const { data } = await adminApi.post(`/closeReport`, details);
  return data;
});

const changeUserStatus = createAsyncThunk(
  "changeUserStatus",
  async (statusObj) => {
    const { data } = await adminApi.post("/changeUserStatus", statusObj);
    return data;
  }
);

const changeReportUserStatus = createAsyncThunk(
  "changeReportUserStatus",
  async (statusObj) => {
    const { data } = await adminApi.post("/changeUserStatus", statusObj);
    return data;
  }
);

const addNotification = createAsyncThunk("addNotification", async (notObj) => {
  const { data } = await adminApi.post("/addNotification", notObj);
  return data;
});

const getNotifications = createAsyncThunk(
  "getNotifications",
  async (details) => {
    const { data } = await adminApi.get(
      `/getNotifications?page=${details.page}&limit=${details.limit}&search=${details.search}`
    );
    return data;
  }
);

const deleteNotification = createAsyncThunk(
  "deleteNotification",
  async (id) => {
    const { data } = await adminApi.delete(`/deleteNotification?id=${id}`);
    return data;
  }
);

const addexercise = createAsyncThunk("addExercise", async (statusObj) => {
  const { data } = await adminApi.post("/createWorkOut", statusObj);
  return data;
});
const workOutList = createAsyncThunk("workOutList", async (details) => {
  const { data } = await adminApi.get(
    `/getWorkOutList?page=${details.pages}&limit=${details.limit}&search=${details.search}`
  );
  return data;
});
const editWorkout = createAsyncThunk("editWorkout", async (details) => {
  const { data } = await adminApi.put(`/updateWorkOut`, details);
  return data;
});
const deleteWorkout = createAsyncThunk("deleteWorkout", async (details) => {
  const { data } = await adminApi.post(`/deleteWorkOut`, details);
  return data;
});

const getSupports = createAsyncThunk("getSupports", async (details) => {
  const { data } = await adminApi.get(
    `/getSupports?page=${details.page}&limit=${details.limit}&search=${details.search}`
  );
  return data;
});
const getReport = createAsyncThunk("getSupports", async (details) => {
  const { data } = await adminApi.get(
    `/getReports?page=${details.pages}&limit=${details.limit}&search=${details.search}`
  );
  return data;
});

const changeSupportStatus = createAsyncThunk(
  "changeSupportStatus",
  async (statusObj) => {
    const { data } = await adminApi.post("/changeSupportStatus", statusObj);
    return data;
  }
);
const chatmessages = createAsyncThunk("chatmessages", async (id) => {
  const { data } = await adminApi.get(`/getSupportChat?id=${id}`);
  return data;
});
const adminchatmessages = createAsyncThunk(
  "adminchatmessages",
  async (details) => {
    const { data } = await adminApi.post(`/sendSupportMessage`, details);
    return data;
  }
);
const shareLink = createAsyncThunk("shareLink", async (id) => {
  const { data } = await adminApi.get(
    `/getUserDetailswithOutToken?user_id=${id}`
  );
  return data;
});

const getCourseCategories = createAsyncThunk(
  "getCourseCategories",
  async () => {
    const { data } = await adminApi.get("/getCourseCategories");
    return data;
  }
);

const createCourseCategory = createAsyncThunk(
  "createCourseCategory",
  async (formdata) => {
    const { data } = await adminApi.post("/createCourseCategory", formdata);
    return data;
  }
);

const getReflections = createAsyncThunk("getReflections", async () => {
  const { data } = await adminApi.get("/getReflections");
  return data;
});

const createReflection = createAsyncThunk(
  "createReflection",
  async (formdata) => {
    const { data } = await adminApi.post("/createReflection", formdata);
    return data;
  }
);

const editReflection = createAsyncThunk("editReflection", async (formdata) => {
  const { data } = await adminApi.post("/editReflection", formdata);
  return data;
});

const deleteReflection = createAsyncThunk("deleteReflection", async (id) => {
  const { data } = await adminApi.delete(`/deleteReflection?id=${id}`);
  return data;
});

const deleteCourseCategory = createAsyncThunk(
  "deleteCourseCategory",
  async (id) => {
    const { data } = await adminApi.delete(`/deleteCourseCategory?id=${id}`);
    return data;
  }
);

const createCourse = createAsyncThunk(
  "createCourse",
  async (formdata, { dispatch }) => {
    const { data } = await adminApi.post("/createCourse", formdata);
    if (data?.success) {
      toast.success(data?.message);
      dispatch(getCourseCategories());
    } else if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const editCourse = createAsyncThunk("editCourse", async (formdata) => {
  const { data } = await adminApi.put("/editCourse", formdata);
  return data;
});

const deleteCourse = createAsyncThunk("deleteCourse", async (id) => {
  const { data } = await adminApi.delete(`/deleteCourse?id=${id}`);
  return data;
});

const getCourses = createAsyncThunk("getCourses", async (id) => {
  const { data } = await adminApi.get(`/getCourses?id=${id}`);
  return data;
});

const getReflectionDetails = createAsyncThunk(
  "getReflectionDetails",
  async (id) => {
    const { data } = await adminApi.get(`/getReflectionDetails?id=${id}`);
    return data;
  }
);

export {
  dashboard,
  getUsers,
  getUserDetails,
  editUserDetails,
  changeUserPass,
  deactivateUser,
  deleteUser,
  getVerificationUsers,
  getReports,
  deleteReport,
  changeUserStatus,
  addNotification,
  getNotifications,
  deleteNotification,
  getSupports,
  changeSupportStatus,
  changeReportUserStatus,
  getCoaches,
  verifyCoach,
  getCoachDetails,
  getCourseCategories,
  getReflections,
  deleteCourseCategory,
  createCourse,
  editCourse,
  getCourses,
  deleteCourse,
  createCourseCategory,
  deleteReflection,
  createReflection,
  editReflection,
  getReflectionDetails,
  VerifiedUsers,
  addexercise,
  workOutList,
  getReport,
  editWorkout,
  deleteWorkout,
  closeReport,
  shareLink,
  chatmessages,
  adminchatmessages,
  getallUsers
};
