import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Card,
  Container,
  Row,
  Form,
  Button,
  Table,
  Badge,
  DropdownButton,
  Stack,
  Dropdown,
} from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSupportStatus,
  getSupports,
} from "../redux/actions/adminActions";
import Pagination from "../components/Pagination";

export default function Support() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.supportSlice.supports);
  const dataforLastMessage = useSelector((state) => state.supportSlice.messages);
  console.log(dataforLastMessage,"lAST")
  console.log(data);
  const [flag, setflag] = useState(null);
  const [pages, setPages] = useState(1);
  const [limit, setlimit] = useState("10");
  const [search, setsearch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSupports({ page: pages, limit: limit, search: search }));
  }, [flag, pages, search, limit]);

  const handleclick = (id, status) => {
    dispatch(changeSupportStatus({ id: id, status: status })).then((res) => {
      if (res?.payload?.success) {
        setflag(!flag);
      }
    });
  };

  const handlesearch = (e) => {
    setsearch(e.target.value);
  };

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <Card className="border-0 p-4 rounded-card user-section ">
              <Stack direction="horizontal" gap={3}>
                <div className="p-2">
                  <h3>Support</h3>
                </div>
                <div className="p-2 ms-auto">
                  <div className="position-relative m-2">
                    <Form.Control
                      type="search"
                      placeholder="Search.."
                      className="serch-feild"
                      name="search"
                      onChange={handlesearch}
                    />
                    {!search && <FiSearch className="searchicon2" />}
                  </div>
                </div>
              </Stack>
              <Table
                responsive
                variant=""
                className="border-0 custom-table mt-3"
              >
                <thead className="first-td">
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Contact Number</th>

                    <th>Message</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.supports?.map((x, i) => {
                    const serialname = (pages - 1) * limit + (i + 1);
                    return (
                      <tr>
                        <td>{serialname}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/admin/support-chat", {
                              state: {
                                id: x?._id,
                              },
                            });
                          }}
                        >
                          {x?.user_id?.full_name || "N/A"}
                          {/* <Link to="/admin/support-chat">
                            {x?.user_id?.full_name}
                          </Link> */}
                        </td>
                        <td>{x?.user_id?.email || "N/A"}</td>
                        <td>{x?.user_id?.phone_number || "N/A"}</td>

                        <td className="disc-text">
                          <p>{x?.user_id?.bio || "N/A"}</p>
                        </td>

                        <td>
                          <Badge bg="primary">
                            {x?.status == 0 ? "In-Processing" : "Resolved"}
                          </Badge>
                        </td>
                        <td>
                          <DropdownButton
                            id="dropdown-item-button "
                            title={
                              x?.status == 0 ? "In-Processing" : "Resolved"
                            }
                            className="border-0 secondary-drop-area"
                          >
                            {[
                              { key: 0, text: "In-Processing" },
                              { key: 1, text: "Resolved" },
                            ]?.map((status) => {
                              return x?.status == status?.key ? (
                                <Dropdown.ItemText>
                                  {status?.text}
                                </Dropdown.ItemText>
                              ) : (
                                <Dropdown.Item
                                  onClick={() =>
                                    handleclick(x?._id, status?.key)
                                  }
                                  as="button"
                                >
                                  {status?.text}
                                </Dropdown.Item>
                              );
                            })}
                          </DropdownButton>
                        </td>
                      </tr>
                    );
                  })}

                  {/* <tr>
                    <td>1</td>
                    <td>
                      <Link to="/admin/support-chat">James</Link>
                    </td>
                    <td>james@yopmail.com</td>
                    <td>9017066657</td>
                    <td>Lorem Ipsum is simply</td>

                    <td>
                      <Badge bg="primary">In-Processing</Badge>
                    </td>
                    <td>
                      <DropdownButton
                        id="dropdown-item-button "
                        title="In-Processing"
                        className="border-0 secondary-drop-area"
                      >
                        <Dropdown.Item as="button">Resolved</Dropdown.Item>
                      </DropdownButton>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
              {data?.data?.supports?.length == 0 && (
                <div className="no-data-updated">
                  <p className="no-data">No data updated yet</p>
                </div>
              )}
            </Card>
          </Row>
        </Container>

        <Pagination
          totalstuff={data?.data?.total}
          limit={data?.data?.limit}
          setpages={setPages}
          search={search}
        />
      </Layout>
    </>
  );
}
