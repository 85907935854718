import React, { useState } from "react"
import Sidebar from "../Layout/Sidebar";
import Header from "../Layout/Header";


export default function Layout(props){
    const [cssclas,setCssclass]=useState("");

    return(
        <>
            <Sidebar setCssclass={setCssclass }/>
            <div className={"content-body px-4 "+ cssclas} >   
            <Header />
                {props.children} 
            </div>
        </>
    )
}