import { useEffect, useState } from "react";
import {
  Card,
  Container,
  Row,
  Form,
  Button,
  Table,
  Badge,
  DropdownButton,
  Stack,
  Dropdown,
} from "react-bootstrap";
import { FiArrowDown } from "react-icons/fi";
import userimg from "../images/profile-user.png";
import { FiSearch } from "react-icons/fi";
import Layout from "../components/Layout/Layout";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserStatus,
  getUsers,
  getallUsers,
} from "../redux/actions/adminActions";
import dummy from "../images/dummy_img.png";
import Pagination from "../components/Pagination";
import { PageNumber } from "../redux/reducers/userMgmtSlice";
import Profile from "../images/Photo_profile.svg";
import * as XLSX from "xlsx";

export default function User() {
  const dispatch = useDispatch();
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(false);
  const users = useSelector((state) => state.userMgmtSlice.users);
  const pagenumbefromredux = useSelector((state) => state.userMgmtSlice.pageno);

  console.log(users);
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState("");
  const [pages, setPages] = useState(
    pagenumbefromredux ? pagenumbefromredux : 1
  );

  useEffect(() => {
    dispatch(getUsers({ pages, limit, search: search }));
  }, [pages, search, flag]);

  const handleEnter = (e) => {
    if (e.key == "Enter") {
      setSearchData(search);
    }
  };

  const handleStatusChange = (id, status) => {
    dispatch(changeUserStatus({ id, status })).then((res) => {
      console.log(res?.payload);
      if (res?.payload?.success) {
        setflag(!flag);
      }
    });
  };

  useEffect(() => {
    dispatch(PageNumber(pages));
  }, [pages]);

  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(getallUsers());
      // console.log(response?.payload?.message);

      const allData = response?.payload?.message;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <Stack className="mb-3 mt-4" direction="horizontal" gap={1}>
              {/* <div className="p-2 ms-auto"><Button className="text-primary-clr" size="lg" variant=""><FiFilter/></Button>{' '}</div> */}
              <div className="p-2 ms-auto">
                <Button
                  className="text-primary-clr align-item-center table-head-btn"
                  size="lg"
                  variant="light"
                  onClick={fetchDataAndExportToExcel}
                >
                  <FiArrowDown /> Download
                </Button>{" "}
              </div>
            </Stack>
            <Card className="border-0 p-4 rounded-card user-section ">
              <Stack
                className="user-table-align"
                direction="horizontal"
                gap={3}
              >
                <div className="p-2">
                  <h3>User Management</h3>
                </div>
                <div className="p-2 ms-auto">
                  <div className="position-relative m-2">
                    <Form.Control
                      type="search"
                      placeholder="Search.."
                      className="serch-feild"
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={handleEnter}
                    />
                    {!search && <FiSearch className="searchicon2" />}
                  </div>
                </div>
              </Stack>
              <Table
                responsive
                variant=""
                className="border-0 custom-table mt-3"
              >
                <thead className="first-td">
                  <tr>
                    <th>S.No.</th>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Location</th>
                    <th>Verified/ Unverified Status</th>
                    <th>Profile Image</th>
                    <th>Gender</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users?.users?.length > 0 &&
                    users?.users?.map((user, i) => {
                      const seralnumber = (pages - 1) * limit + i + 1;
                      return (
                        <tr key={user?._id}>
                          <td>{seralnumber}</td>
                          <td>
                            <Link to={`/admin/userdetails/${user?._id}`}>
                              {user?.full_name || "N/A"}
                            </Link>
                            {/* {user?.full_name} */}
                          </td>
                          <td>{user?.age ? user?.age : "N/A"}</td>
                          <td>
                            {user?.address
                              ? user?.address?.split("-")[0]
                              : "N/A"}
                          </td>
                          <td>
                            <Badge
                              bg={
                                user?.admin_verified == 1
                                  ? "success"
                                  : "warning"
                              }
                            >
                              {user?.admin_verified == 1
                                ? "VERIFIED"
                                : "UN-VERIFIED"}
                            </Badge>
                          </td>
                          <td>
                            <img
                              src={
                                user?.profile_image
                                  ? BASE_URL + "/" + user?.profile_image
                                  : Profile
                              }
                              alt="user image"
                              className="rounded-circle profile-image"
                            />
                          </td>
                          <td>
                            {user?.gender == 0
                              ? "Female"
                              : user?.gender == 1
                              ? "Male"
                              : "Other"}
                          </td>
                          <td>
                            <Badge bg="primary">
                              {user?.status == 1
                                ? "ACTIVE"
                                : user?.status == 2
                                ? "TEMPORARILY BANNED"
                                : user?.status == 3 && "PERMANENT BANNED"}
                            </Badge>
                          </td>
                          <td>
                            <DropdownButton
                              id="dropdown-item-button "
                              title={
                                user?.status == 1
                                  ? "Active"
                                  : user?.status == 2
                                  ? "Temporarily Banned"
                                  : user?.status == 3 && "Permanent Banned"
                              }
                              className="border-0 secondary-drop-area"
                            >
                              {[
                                { key: 1, text: "Active" },
                                { key: 2, text: "Temporarily Banned" },
                                { key: 3, text: "Permanent Banned" },
                              ]?.map((status) => {
                                return user?.status == status?.key ? (
                                  <Dropdown.ItemText>
                                    {status?.text}
                                  </Dropdown.ItemText>
                                ) : (
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleStatusChange(user?._id, status?.key)
                                    }
                                    as="button"
                                  >
                                    {status?.text}
                                  </Dropdown.Item>
                                );
                              })}
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {users?.users?.length === 0 && (
                <div className="no-data-updated">
                  <p className="no-data">No data updated yet</p>
                </div>
              )}
            </Card>
          </Row>
        </Container>

        <Pagination
          totalstuff={users?.totalUser}
          limit={users?.limit}
          setpages={setPages}
          search={search}
        />
      </Layout>
    </>
  );
}
