import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Card,
  Container,
  Row,
  Form,
  Button,
  Table,
  Stack,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  closeReport,
  deleteReport,
  getReport,
} from "../redux/actions/adminActions";
import { toast } from "react-toastify";
import Pagination from "../components/Pagination";

export default function Report() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.reSlice.reports);
  console.log(data);

  const [limit, setlimit] = useState("10");
  const [search, setSearch] = useState("");
  const [pages, setPages] = useState(1);
  const [flag, setflag] = useState(true);

  useEffect(() => {
    dispatch(getReport({ pages: pages, search: search, limit: limit }));
  }, [flag, search, pages, limit]);

  const handledelete = (id) => {
    dispatch(deleteReport(id)).then((res) => {
      if (res?.payload?.success) {
        toast.success(res?.payload?.message);
        setflag(!flag);
      } else {
        toast.console.error(res?.payload?.message);
      }
    });
  };

  const handlereportStatus = (id) => {
    dispatch(closeReport({ id: id, status: 0 })).then((res) => {
      if (res?.payload?.success) {
        toast.success(res?.payload?.message);
        setflag(!flag);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const handleActive = (id) => {
    dispatch(closeReport({ id: id, status: 1 })).then((res) => {
      if (res?.payload?.success) {
        toast.success(res?.payload?.message);
        setflag(!flag);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <Card className="border-0 p-4 rounded-card user-section ">
              <Stack direction="horizontal" gap={3}>
                <div className="p-2">
                  <h3>Report</h3>
                </div>
              </Stack>
              <Table
                responsive
                variant=""
                className="border-0 custom-table mt-3"
              >
                <thead className="first-td">
                  <tr>
                    <th>S.No</th>
                    <th>Report by</th>
                    <th>Report to</th>
                    <th>Reason</th>
                    <th>Description</th>
                    <th>Status </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.reports?.map((x, i) => {
                    const seralnumber = (pages - 1) * limit + i + 1;
                    return (
                      <tr>
                        <td>{seralnumber}</td>
                        <td>{x?.blockBy?.full_name || "N/A"}</td>
                        <td>{x?.blockTo?.full_name || "N/A"}</td>
                        <td>{x?.reason || "N/A"}</td>
                        <td>{x?.desc || "N/A"}</td>
                        <td>
                          <DropdownButton
                            title={x?.status == 1 ? "Active" : "Closed"}
                            id="dropdown-item-button "
                            className="border-0 secondary-drop-area"
                          >
                            <Dropdown.Item
                              onClick={() => handleActive(x?._id)}
                              as="button"
                            >
                              Active
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={() => handlereportStatus(x?._id)}
                              as="button"
                            >
                              Closed
                            </Dropdown.Item>
                          </DropdownButton>
                        </td>
                        <td>
                          <Stack
                            direction="horizontal"
                            className="table-btn"
                            gap={3}
                          >
                            <Button
                              onClick={() => handlereportStatus(x?._id)}
                              as="a"
                              variant="primary"
                            >
                              {x?.status == 0 ? "Closed" : "Close"}
                            </Button>
                            <Button
                              onClick={() => handledelete(x?._id)}
                              as="a"
                              variant="primary"
                            >
                              Delete
                            </Button>
                          </Stack>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {data?.data?.reports?.length == 0 && (
                <div className="no-data-updated">
                  <p className="no-data">No data updated yet</p>
                </div>
              )}
            </Card>
          </Row>
        </Container>

        <Pagination
          totalstuff={data?.data?.totalUser}
          limit={data?.data?.limit}
          setpages={setPages}
          search={search}
        />
      </Layout>
    </>
  );
}
