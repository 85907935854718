import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  deleteReflection,
  getReflectionDetails,
  getReflections,
} from "../actions/adminActions";
const initialState = {
  reflections: null,
  reflectionDetails: null,
};

export const reflectionSlice = createSlice({
  name: "reflectionSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReflections.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.reflections = payload.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getReflectionDetails.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.reflectionDetails = payload.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteReflection.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.reflections = state.reflections.filter(
            (ref) => ref?._id != payload.data?._id
          );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default reflectionSlice.reducer;
