import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addexercise,
  getCoachDetails,
  getCoaches,
  verifyCoach,
  workOutList,
} from "../actions/adminActions";

const initialState = {
  exercise: null,
  exerciseList: null,
};

export const exerciseSlice = createSlice({
  name: "exerciseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addexercise.fulfilled, (state, { payload }) => {
        if (payload.success) {
          toast.success(payload.message);
          // state.totalCoaches = payload.data.totalCoaches;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(workOutList.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.exerciseList = payload;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default exerciseSlice.reducer;
