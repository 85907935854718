import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer(){
    return(
        <>
        <Container className="footer" fluid="lg">
            <Row>
                <Col>
                <div className="text-muted">Copyright © 2024 Spotlifts, All rights reserved.</div>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default Footer;