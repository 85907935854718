import React from "react";
import Layout from "../components/Layout/Layout";
import { Col, Container, Row, Card, Image } from "react-bootstrap";
import dashbordbg from "../images/dasboard-bg.png";
import usericon from "../images/user-icon.png";
import discount from "../images/Discount.png";
import user2 from "../images/2 User.png";
import verifyuser from "../images/Vierify-user.png";
import GenderChart from "../components/elements/GenderChart";
import UserChart from "../components/elements/UserChart";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { dashboard } from "../redux/actions/adminActions";

export default function Dashboard() {
  const dispatch = useDispatch();

  const dash = useSelector((state) => state.dashboardSlice.dashboard);

  useEffect(() => {
    dispatch(dashboard());
  }, []);

  return (
    <>
      <Layout>
        <Container fluid>
          <Row lg={4} md={3} xs={1} className="mt-4">
            <Col>
              <Card className="text-white dasboard-card card-orange p-3">
                <Card.Img src={dashbordbg} alt="Card image" />
                <Card.ImgOverlay>
                  <Card.Text className="d-flex justify-content-between">
                    <div>
                      <Card.Title>Total Users</Card.Title>
                      <Card.Text>{dash?.totalUsers || 0}</Card.Text>
                    </div>
                    <div className="card-icon-box-1 card-icon-orange">
                      <Image src={usericon} />
                    </div>
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
            <Col>
              <Card className="text-white dasboard-card card-blue p-3">
                <Card.Img src={dashbordbg} alt="Card image" />
                <Card.ImgOverlay>
                  <Card.Text className="d-flex justify-content-between">
                    <div>
                      <Card.Title>Total Subscriber</Card.Title>
                      <Card.Text>0</Card.Text>
                    </div>
                    <div className="card-icon-box-1 card-icon-blue">
                      <Image src={discount} />
                    </div>
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
            <Col>
              <Card className="text-white dasboard-card card-sky p-3">
                <Card.Img src={dashbordbg} alt="Card image" />
                <Card.ImgOverlay>
                  <Card.Text className="d-flex justify-content-between">
                    <div>
                      <Card.Title>Total free Users</Card.Title>
                      <Card.Text>0</Card.Text>
                    </div>
                    <div className="card-icon-box-1 card-icon-sky">
                      <Image src={user2} />
                    </div>
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
            <Col>
              <Card className="text-white dasboard-card  card-green p-3">
                <Card.Img src={dashbordbg} alt="Card image" />
                <Card.ImgOverlay>
                  <Card.Text className="d-flex justify-content-between">
                    <div>
                      <Card.Title>Total Matches</Card.Title>
                      <Card.Text>{dash?.totalMatches || 0}</Card.Text>
                    </div>
                    <div className="card-icon-box-1 card-icon-green">
                      <Image src={verifyuser} />
                    </div>
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg="6">
              <Card className="p-4 border-0 rounded-card mb-2">
                <h4>Gender Ratio</h4>
                <GenderChart genderData={dash?.genderRatio} />
              </Card>
            </Col>

            <Col lg="6">
              <Card className="p-4 border-0 rounded-card mb-2">
                <h4>User Ratio</h4>
                <UserChart verifyData={dash?.verifiedRatio} />
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
