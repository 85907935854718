import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../services/adminApi";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import moment from "moment/moment";

const cookies = new Cookies();

const login = createAsyncThunk(
  "login",
  async ({ loginObj, keepMeLoggedIn }) => {
    const { data } = await adminApi.post("/auth/login", loginObj);
    if (data?.success) {
      sessionStorage.setItem("token", data?.data?.token);
      if (keepMeLoggedIn) {
        cookies.set("token", data?.data?.token, {
          path: "/",
          expires: moment().add(7, "days").toDate(),
        });
      }
      toast(data?.message);
      window.location.href = "/admin/dashboard";
    } else if (!data?.success) {
      toast.error(data?.message);
    }
  }
);

const changePass = createAsyncThunk(
  "changePass",
  async ( dataObj ) => {
    const { data } = await adminApi.put("/changePass", dataObj);
    if (data?.success) {
       toast(data?.message);
      window.location.href = "/admin/dashboard";
    } else if (!data?.success) {
      toast.error(data?.message);
    }
  }
);

export { login,changePass };
