import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserPass,
  changeUserStatus,
  deactivateUser,
  deleteUser,
  editUserDetails,
  getUserDetails,
} from "../redux/actions/adminActions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Field } from "formik";
import Select from "react-select";
import countriesWcode from "../components/constants/countriesWCode";
import countriesWDialCode from "../components/constants/countriesWDialCode";
import { toast } from "react-toastify";
import moment from "moment";
import { CleaningServices } from "@mui/icons-material";
import Profile from "../images/Photo_profile.svg";

const UserDetails = () => {
  const countries = countriesWcode;
  const FILE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userMgmtSlice.userDetails);
  console.log(userDetails);
  const [show, setShow] = useState(false);
  const [switchVal, setSwitch] = useState();
  const [delShow, setDelShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedCode, setSelectedCode] = useState();
  const [prevImg, setPrevImg] = useState();
  const [status, setstatus] = useState(null);
  const [flag, setflag] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  console.log(selectedImage,"image")
  console.log(userDetails?.post[selectedImage]?.image ,"hsagfjhd")

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserDetails(id));
  }, [flag]);

  useEffect(() => {
    if (userDetails) {
      setSelectedCode({
        label: userDetails?.country_code,
        value: userDetails?.country_code,
      });

      setPrevImg(
        userDetails?.profile_image
          ? FILE_URL + "/" + userDetails?.profile_image
          : Profile
      );

      setSwitch(userDetails?.deactivated == 1 ? true : false);
    }
  }, [userDetails]);

  const themeMode = true;

  const customStyles = {
    // control: (provided, state) => ({
    //   ...provided,
    //   width: 68,

    // }),

    // dropdownIndicator: (provided) => ({
    //   ...provided,
    //   width: "15px",
    //   height: "20px",
    //   padding: -5,
    //   marginLeft: "50px",
    //   marginTop: -40,
    //   color: themeMode ? "#6e6e6e" : "white",
    // }),

    singleValue: (provided) => ({
      ...provided,
      color: themeMode ? "#6e6e6e" : "white",
    }),
    control: (provided, state) => ({
      ...provided,
      width: 68,
      backgroundColor: themeMode ? "white" : "#1e1e1e",
      border: `1px solid ${themeMode ? "#cccccc" : "#444444"}`,
      height: "48px",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "1px solid #b637c3" : "none",
      "&:hover": {
        border: "1px solid #b637c3",
      },
      // borderColor: state.isFocused ? "#ab91ed" : "#282828",
    }),

    input: (provided) => ({
      ...provided,
      color: themeMode ? "#6e6e6e" : "white",
      margin: "0px",
      padding: "8px",
    }),
    menu: (provided) => ({
      ...provided,
      width: 200, // Set the width for the dropdown menu
    }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: (provided) => ({
      // ...provided,
      // with: "1px",
      ...provided,
      width: "15px",
      height: "20px",
      padding: -5,
      marginLeft: "50px",
      marginTop: -43,
    }),
    clearIndicator: (provided) => ({
      // ...provided,
      display: "none",
    }),
  };

  const handlestatus = (e) => {
    // setstatus(e.target.value);
    console.log(id);
    dispatch(changeUserStatus({ id, status: e.target.value })).then((res) => {
      console.log(res?.payload);
      if (res?.payload?.success) {
        setflag(!flag);
      } else {
        toast.error(res?.payload?.error);
      }
    });
  };

  // useEffect(() => {

  // }, [status]);

  const handleDeactivate = (e) => {
    // console.log(e.target.value)

    const ob = {
      userId: id,
      deactivatedStatus: userDetails?.deactivated == 0?1:0,
    };
    dispatch(deactivateUser(ob)).then((res) => {
      console.log(res?.payload);
      if (res?.payload?.success) {
        setflag(!flag);
        handleClose();
      } else {
        toast.error(res?.payload?.error);
      }
    })
    // setSwitch((prev) => !prev);
    
  };

  const handleAccDel = () => {
    dispatch(deleteUser(id));
    navigate("/admin/users");
    setDelShow(false);
  };

  const openLightbox = (index) => {
    setSelectedImage(index);
  };
  console.log(selectedImage)

  const closeLightbox = () => {
    setSelectedImage(null);
  };

  return (
    <Layout>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="mb-4">
              <Link to="/admin/users">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M3 7.99935L1.93934 9.06001L0.87868 7.99935L1.93934 6.93869L3 7.99935ZM23 6.49935C23.8284 6.49935 24.5 7.17092 24.5 7.99935C24.5 8.82778 23.8284 9.49935 23 9.49935V6.49935ZM8.60601 15.7267L1.93934 9.06001L4.06066 6.93869L10.7273 13.6054L8.60601 15.7267ZM1.93934 6.93869L8.60601 0.272022L10.7273 2.39334L4.06066 9.06001L1.93934 6.93869ZM3 6.49935L23 6.49935V9.49935L3 9.49935L3 6.49935Z"
                    fill="#40413A"
                  />
                </svg>
              </Link>
            </div>
          </Col>
        </Row>
        <div className="card_main">
          <Row>
            <Formik
              enableReinitialize
              initialValues={{
                email: userDetails?.email,
                full_name: userDetails?.full_name,
                phone_number: userDetails?.phone_number,
                suspended: userDetails?.suspended,
                image: null,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Enter Email Address";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                if (!values.full_name) {
                  errors.full_name = "Enter full name";
                }
                if (!values?.phone_number) {
                  errors.phone_number = "Enter Phone Number";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (!selectedCode)
                  return toast.warning("Please select a country code");

                const formdata = new FormData();
                formdata.append("userId", id);
                formdata.append("full_name", values?.full_name);
                formdata.append("email", values?.email);
                formdata.append("phone_number", values?.phone_number);
                values?.suspended &&
                  formdata.append("suspended", values?.suspended);
                formdata.append("country_code", selectedCode.value);
                values?.image &&
                  formdata.append("profile_image", values?.image);

                dispatch(editUserDetails(formdata));
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={2}>
                      <div className="input_file_f">
                        <img src={prevImg} alt="Profile" />

                        <div className="upload_btn">
                          <p>Upload</p>
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                            onChange={(event) => {
                              if (event.currentTarget.files[0]) {
                                setFieldValue(
                                  "image",
                                  event.currentTarget.files[0]
                                );
                                setPrevImg(
                                  URL.createObjectURL(
                                    event.currentTarget.files[0]
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="inputs_main">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Full Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="full_name"
                            placeholder="Full name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.full_name}
                          />
                          <span className="formik-errors">
                            {errors?.full_name &&
                              touched?.full_name &&
                              errors?.full_name}
                          </span>
                        </Form.Group>
                        <Form.Group
                          className="mb-3 phone_input"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Phone Number</Form.Label>
                          <div className="d-flex">
                            <Select
                              className="phone_select"
                              value={
                                selectedCode
                                  ? {
                                      label: selectedCode.value,
                                      value: selectedCode.value,
                                    }
                                  : null
                              }
                              onChange={(e) => setSelectedCode(e)}
                              options={countriesWDialCode}
                              isClearable={true}
                              placeholder="+00"
                              styles={customStyles}
                            />
                            <Form.Control
                              type="number"
                              placeholder="Phone Number"
                              name="phone_number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.phone_number}
                            />
                          </div>
                          <span className="formik-errors">
                            {errors?.phone_number &&
                              touched?.phone_number &&
                              errors?.phone_number}
                          </span>
                        </Form.Group>

                        <div className="sub_btn">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="inputs_main">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.email}
                          />
                          <span className="formik-errors">
                            {errors?.email && touched?.email && errors?.email}
                          </span>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Status</Form.Label>

                          <Field
                            as="select"
                            id="status"
                            name="suspended"
                            onChange={handlestatus}
                            value={userDetails?.status}
                            aria-label="Default select example"
                          >
                            <option value={1}>Active</option>
                            <option value={2}>Temporarily Banned</option>
                            <option value={3}>Permanent Banned</option>
                          </Field>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          </Row>
        </div>

        <Row>
          <Col lg={6}>
            <div className="card_small">
              <div className="head">
                <h2>Other Information</h2>
              </div>
              <div className="icons_list">
                <ul>
                  <li>
                    <svg
                      className=""
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="AirplayIcon"
                    >
                      <path d="M6 22h12l-6-6z"></path>
                      <path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h4v-2H3V5h18v12h-4v2h4c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                    </svg>
                    <div className="icons_right">
                      <p>Last Login</p>
                      <h6>
                        {moment(userDetails?.lastLogin).format("Do MMMM YYYY")}
                      </h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="SpaceDashboardIcon"
                    >
                      <path d="M11 21H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h6v18zm2 0h6c1.1 0 2-.9 2-2v-7h-8v9zm8-11V5c0-1.1-.9-2-2-2h-6v7h8z"></path>
                    </svg>

                    <div className="icons_right">
                      <p>Login type</p>
                      <h6>General</h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="CalendarMonthIcon"
                    >
                      <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"></path>
                    </svg>

                    <div className="icons_right">
                      <p>Registered Date</p>
                      <h6>
                        {moment(userDetails?.createdAt).format("Do MMMM YYYY")}
                      </h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="PhoneAndroidIcon"
                    >
                      <path d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3H6.75V4h10.5v14z"></path>
                    </svg>
                    <div className="icons_right">
                      <p>Device Type</p>
                      <h6>
                        {userDetails?.device_type == 0 ? "Android" : "iOS"}
                      </h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="ReportGmailerrorredIcon"
                    >
                      <path d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9 14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8z"></path>
                      <path d="M11 7h2v6h-2zm0 8h2v2h-2z"></path>
                    </svg>
                    <div className="icons_right">
                      <p>Device Model</p>
                      <h6>{userDetails?.device_model}</h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="LocationOnIcon"
                    >
                      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
                    </svg>
                    <div className="icons_right">
                      <p>Current Gym </p>
                      <h6>{userDetails?.current_gym_name || "N/A"}</h6>
                    </div>
                  </li>

                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="PermIdentityIcon"
                    >
                      <path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"></path>
                    </svg>
                    <div className="icons_right">
                      <p>Looking For</p>
                      <h6>
                        {userDetails?.lookingFor
                          ? userDetails?.lookingFor == 0
                            ? "Female"
                            : userDetails?.lookingFor == 1
                            ? "Male"
                            : userDetails?.lookingFor == 3 &&
                              "Prefer not to say"
                          : "N/A"}
                      </h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="95"
                      height="48"
                      viewBox="0 0 95 48"
                      fill="none"
                    >
                      <g>
                        <path d="M22.12 19.5004C38.91 19.5004 55.43 19.5004 72.14 19.5004C72.19 18.8004 72.27 18.1404 72.27 17.4704C72.28 13.3804 72.27 9.30043 72.28 5.21043C72.28 0.720428 73 0.0104271 77.57 0.0204271C77.88 0.0204271 78.2 0.0104271 78.51 0.0204271C80.68 0.110427 81.87 1.27043 81.92 3.44043C81.97 5.20043 81.93 6.96043 81.93 8.72043C81.93 20.1004 81.93 31.4804 81.93 42.8604C81.93 46.7004 81.04 47.5804 77.13 47.5804C76.82 47.5804 76.5 47.5804 76.19 47.5804C73.46 47.5404 72.28 46.4204 72.26 43.6704C72.22 39.2104 72.25 34.7404 72.25 30.2804C72.25 29.6004 72.25 28.9204 72.25 28.1204C55.49 28.1204 38.93 28.1204 22.12 28.1204C22.12 28.8804 22.12 29.5504 22.12 30.2204C22.12 34.5004 22.13 38.7704 22.12 43.0504C22.11 46.5704 21.12 47.5704 17.66 47.5804C17.03 47.5804 16.4 47.6004 15.77 47.5704C13.72 47.4604 12.45 46.2704 12.44 44.2404C12.41 30.5904 12.41 16.9504 12.44 3.30043C12.44 1.35043 13.68 0.0804271 15.61 0.0304271C20.17 -0.0995729 22.38 -0.519573 22.14 6.07043C21.99 10.4804 22.12 14.8804 22.12 19.5004Z" />
                        <path d="M4.94 18.8108C4.94 15.2808 4.93 11.9508 4.94 8.63085C4.95 6.15085 5.9 5.06085 8.03 5.05085C10.18 5.04085 11.19 6.12085 11.2 8.57085C11.22 18.7608 11.22 28.9509 11.2 39.1409C11.2 41.5609 10.14 42.6509 7.98 42.6109C5.94 42.5709 4.96 41.4909 4.95 39.1609C4.93 35.7809 4.95 32.3909 4.95 29.1009C4.69 28.9409 4.59 28.8309 4.49 28.8309C0.24 28.5609 0 28.3109 0 23.9809C0 23.4809 0 22.9708 0 22.4708C0.02 19.7009 0.76 18.9508 3.6 18.8908C3.96 18.8708 4.33 18.8408 4.94 18.8108Z" />
                        <path d="M89.42 18.8903C90.3 18.8903 90.92 18.8603 91.54 18.8903C93.34 18.9803 94.27 19.9003 94.35 21.7403C94.4 22.8703 94.37 24.0003 94.36 25.1403C94.35 27.9303 93.58 28.7003 90.8 28.7603C90.43 28.7703 90.06 28.8003 89.42 28.8303C89.42 32.3703 89.45 35.8603 89.41 39.3603C89.39 41.6903 88.04 42.8503 85.79 42.5803C84.03 42.3803 83.2 41.3803 83.17 39.2903C83.13 35.8903 83.16 32.5003 83.16 29.1003C83.16 22.2503 83.15 15.3903 83.17 8.54033C83.18 6.37033 83.92 5.36033 85.6 5.08033C87.92 4.69033 89.39 5.89033 89.41 8.27033C89.45 11.7203 89.42 15.1703 89.42 18.8903Z" />
                      </g>
                    </svg>
                    <div className="icons_right">
                      <p>Lifting Type</p>
                      <h6>{userDetails?.lifter_type || "N/A"}</h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Col>

          <Col lg={6}>
            <div className="card_small">
              <div className="head">
                <h2>Best Lifts</h2>
              </div>
              {userDetails?.excercise1 ? 
              <div className="icons_list">
                <ul>
                  {userDetails?.excercise1 ? (
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="95"
                        height="48"
                        viewBox="0 0 95 48"
                        fill="none"
                      >
                        <g>
                          <path d="M22.12 19.5004C38.91 19.5004 55.43 19.5004 72.14 19.5004C72.19 18.8004 72.27 18.1404 72.27 17.4704C72.28 13.3804 72.27 9.30043 72.28 5.21043C72.28 0.720428 73 0.0104271 77.57 0.0204271C77.88 0.0204271 78.2 0.0104271 78.51 0.0204271C80.68 0.110427 81.87 1.27043 81.92 3.44043C81.97 5.20043 81.93 6.96043 81.93 8.72043C81.93 20.1004 81.93 31.4804 81.93 42.8604C81.93 46.7004 81.04 47.5804 77.13 47.5804C76.82 47.5804 76.5 47.5804 76.19 47.5804C73.46 47.5404 72.28 46.4204 72.26 43.6704C72.22 39.2104 72.25 34.7404 72.25 30.2804C72.25 29.6004 72.25 28.9204 72.25 28.1204C55.49 28.1204 38.93 28.1204 22.12 28.1204C22.12 28.8804 22.12 29.5504 22.12 30.2204C22.12 34.5004 22.13 38.7704 22.12 43.0504C22.11 46.5704 21.12 47.5704 17.66 47.5804C17.03 47.5804 16.4 47.6004 15.77 47.5704C13.72 47.4604 12.45 46.2704 12.44 44.2404C12.41 30.5904 12.41 16.9504 12.44 3.30043C12.44 1.35043 13.68 0.0804271 15.61 0.0304271C20.17 -0.0995729 22.38 -0.519573 22.14 6.07043C21.99 10.4804 22.12 14.8804 22.12 19.5004Z" />
                          <path d="M4.94 18.8108C4.94 15.2808 4.93 11.9508 4.94 8.63085C4.95 6.15085 5.9 5.06085 8.03 5.05085C10.18 5.04085 11.19 6.12085 11.2 8.57085C11.22 18.7608 11.22 28.9509 11.2 39.1409C11.2 41.5609 10.14 42.6509 7.98 42.6109C5.94 42.5709 4.96 41.4909 4.95 39.1609C4.93 35.7809 4.95 32.3909 4.95 29.1009C4.69 28.9409 4.59 28.8309 4.49 28.8309C0.24 28.5609 0 28.3109 0 23.9809C0 23.4809 0 22.9708 0 22.4708C0.02 19.7009 0.76 18.9508 3.6 18.8908C3.96 18.8708 4.33 18.8408 4.94 18.8108Z" />
                          <path d="M89.42 18.8903C90.3 18.8903 90.92 18.8603 91.54 18.8903C93.34 18.9803 94.27 19.9003 94.35 21.7403C94.4 22.8703 94.37 24.0003 94.36 25.1403C94.35 27.9303 93.58 28.7003 90.8 28.7603C90.43 28.7703 90.06 28.8003 89.42 28.8303C89.42 32.3703 89.45 35.8603 89.41 39.3603C89.39 41.6903 88.04 42.8503 85.79 42.5803C84.03 42.3803 83.2 41.3803 83.17 39.2903C83.13 35.8903 83.16 32.5003 83.16 29.1003C83.16 22.2503 83.15 15.3903 83.17 8.54033C83.18 6.37033 83.92 5.36033 85.6 5.08033C87.92 4.69033 89.39 5.89033 89.41 8.27033C89.45 11.7203 89.42 15.1703 89.42 18.8903Z" />
                        </g>
                      </svg>

                      <div className="icons_right">
                        <p>{userDetails?.excercise1 || "N/A"}</p>
                        <h6>
                          {userDetails?.excercise_value2
                            ? userDetails?.excercise_value1 + "Kg"
                            : ""}
                        </h6>
                      </div>
                    </li>
                  ) : null}
                  {userDetails?.excercise2 ? (
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="95"
                        height="48"
                        viewBox="0 0 95 48"
                        fill="none"
                      >
                        <g>
                          <path d="M22.12 19.5004C38.91 19.5004 55.43 19.5004 72.14 19.5004C72.19 18.8004 72.27 18.1404 72.27 17.4704C72.28 13.3804 72.27 9.30043 72.28 5.21043C72.28 0.720428 73 0.0104271 77.57 0.0204271C77.88 0.0204271 78.2 0.0104271 78.51 0.0204271C80.68 0.110427 81.87 1.27043 81.92 3.44043C81.97 5.20043 81.93 6.96043 81.93 8.72043C81.93 20.1004 81.93 31.4804 81.93 42.8604C81.93 46.7004 81.04 47.5804 77.13 47.5804C76.82 47.5804 76.5 47.5804 76.19 47.5804C73.46 47.5404 72.28 46.4204 72.26 43.6704C72.22 39.2104 72.25 34.7404 72.25 30.2804C72.25 29.6004 72.25 28.9204 72.25 28.1204C55.49 28.1204 38.93 28.1204 22.12 28.1204C22.12 28.8804 22.12 29.5504 22.12 30.2204C22.12 34.5004 22.13 38.7704 22.12 43.0504C22.11 46.5704 21.12 47.5704 17.66 47.5804C17.03 47.5804 16.4 47.6004 15.77 47.5704C13.72 47.4604 12.45 46.2704 12.44 44.2404C12.41 30.5904 12.41 16.9504 12.44 3.30043C12.44 1.35043 13.68 0.0804271 15.61 0.0304271C20.17 -0.0995729 22.38 -0.519573 22.14 6.07043C21.99 10.4804 22.12 14.8804 22.12 19.5004Z" />
                          <path d="M4.94 18.8108C4.94 15.2808 4.93 11.9508 4.94 8.63085C4.95 6.15085 5.9 5.06085 8.03 5.05085C10.18 5.04085 11.19 6.12085 11.2 8.57085C11.22 18.7608 11.22 28.9509 11.2 39.1409C11.2 41.5609 10.14 42.6509 7.98 42.6109C5.94 42.5709 4.96 41.4909 4.95 39.1609C4.93 35.7809 4.95 32.3909 4.95 29.1009C4.69 28.9409 4.59 28.8309 4.49 28.8309C0.24 28.5609 0 28.3109 0 23.9809C0 23.4809 0 22.9708 0 22.4708C0.02 19.7009 0.76 18.9508 3.6 18.8908C3.96 18.8708 4.33 18.8408 4.94 18.8108Z" />
                          <path d="M89.42 18.8903C90.3 18.8903 90.92 18.8603 91.54 18.8903C93.34 18.9803 94.27 19.9003 94.35 21.7403C94.4 22.8703 94.37 24.0003 94.36 25.1403C94.35 27.9303 93.58 28.7003 90.8 28.7603C90.43 28.7703 90.06 28.8003 89.42 28.8303C89.42 32.3703 89.45 35.8603 89.41 39.3603C89.39 41.6903 88.04 42.8503 85.79 42.5803C84.03 42.3803 83.2 41.3803 83.17 39.2903C83.13 35.8903 83.16 32.5003 83.16 29.1003C83.16 22.2503 83.15 15.3903 83.17 8.54033C83.18 6.37033 83.92 5.36033 85.6 5.08033C87.92 4.69033 89.39 5.89033 89.41 8.27033C89.45 11.7203 89.42 15.1703 89.42 18.8903Z" />
                        </g>
                      </svg>

                      <div className="icons_right">
                        <p>{userDetails?.excercise2 || "N/A"}</p>
                        <h6>
                          {userDetails?.excercise_value2
                            ? userDetails?.excercise_value2 + "Kg"
                            : ""}
                        </h6>
                      </div>
                    </li>
                  ) : null}
                  {userDetails?.excercise_3 ? (
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="95"
                        height="48"
                        viewBox="0 0 95 48"
                        fill="none"
                      >
                        <g>
                          <path d="M22.12 19.5004C38.91 19.5004 55.43 19.5004 72.14 19.5004C72.19 18.8004 72.27 18.1404 72.27 17.4704C72.28 13.3804 72.27 9.30043 72.28 5.21043C72.28 0.720428 73 0.0104271 77.57 0.0204271C77.88 0.0204271 78.2 0.0104271 78.51 0.0204271C80.68 0.110427 81.87 1.27043 81.92 3.44043C81.97 5.20043 81.93 6.96043 81.93 8.72043C81.93 20.1004 81.93 31.4804 81.93 42.8604C81.93 46.7004 81.04 47.5804 77.13 47.5804C76.82 47.5804 76.5 47.5804 76.19 47.5804C73.46 47.5404 72.28 46.4204 72.26 43.6704C72.22 39.2104 72.25 34.7404 72.25 30.2804C72.25 29.6004 72.25 28.9204 72.25 28.1204C55.49 28.1204 38.93 28.1204 22.12 28.1204C22.12 28.8804 22.12 29.5504 22.12 30.2204C22.12 34.5004 22.13 38.7704 22.12 43.0504C22.11 46.5704 21.12 47.5704 17.66 47.5804C17.03 47.5804 16.4 47.6004 15.77 47.5704C13.72 47.4604 12.45 46.2704 12.44 44.2404C12.41 30.5904 12.41 16.9504 12.44 3.30043C12.44 1.35043 13.68 0.0804271 15.61 0.0304271C20.17 -0.0995729 22.38 -0.519573 22.14 6.07043C21.99 10.4804 22.12 14.8804 22.12 19.5004Z" />
                          <path d="M4.94 18.8108C4.94 15.2808 4.93 11.9508 4.94 8.63085C4.95 6.15085 5.9 5.06085 8.03 5.05085C10.18 5.04085 11.19 6.12085 11.2 8.57085C11.22 18.7608 11.22 28.9509 11.2 39.1409C11.2 41.5609 10.14 42.6509 7.98 42.6109C5.94 42.5709 4.96 41.4909 4.95 39.1609C4.93 35.7809 4.95 32.3909 4.95 29.1009C4.69 28.9409 4.59 28.8309 4.49 28.8309C0.24 28.5609 0 28.3109 0 23.9809C0 23.4809 0 22.9708 0 22.4708C0.02 19.7009 0.76 18.9508 3.6 18.8908C3.96 18.8708 4.33 18.8408 4.94 18.8108Z" />
                          <path d="M89.42 18.8903C90.3 18.8903 90.92 18.8603 91.54 18.8903C93.34 18.9803 94.27 19.9003 94.35 21.7403C94.4 22.8703 94.37 24.0003 94.36 25.1403C94.35 27.9303 93.58 28.7003 90.8 28.7603C90.43 28.7703 90.06 28.8003 89.42 28.8303C89.42 32.3703 89.45 35.8603 89.41 39.3603C89.39 41.6903 88.04 42.8503 85.79 42.5803C84.03 42.3803 83.2 41.3803 83.17 39.2903C83.13 35.8903 83.16 32.5003 83.16 29.1003C83.16 22.2503 83.15 15.3903 83.17 8.54033C83.18 6.37033 83.92 5.36033 85.6 5.08033C87.92 4.69033 89.39 5.89033 89.41 8.27033C89.45 11.7203 89.42 15.1703 89.42 18.8903Z" />
                        </g>
                      </svg>

                      <div className="icons_right">
                        <p>{userDetails?.excercise_3 || "N/A"}</p>
                        <h6>
                          {userDetails?.excercise_value3
                            ? userDetails?.excercise_value3 + "Kg"
                            : ""}
                        </h6>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div> : <p style={{paddingTop:"20px"}}>No data available yet</p>}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Row className="set_height">
              <Col lg={12}>
                <div className="card_small">
                  <div className="head">
                    <h2>De-Activate Account</h2>
                  </div>
                  <div className="toggle">
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      label="You will not be able to receive messages, notifications for up to 24 hours."
                      onClick={handleShow}
                      checked={userDetails?.deactivated}
                      // checked={userDetails?.deactivated}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="card_small">
                  <div className="head">
                    <h2>Delete Account</h2>
                  </div>
                  <div className="dlt_acount">
                    <h6>
                      Once you delete the account, there is no going back.
                      Please be certain.
                    </h6>
                    <button
                      onClick={() => setDelShow(true)}
                      className="btn btn-danger "
                    >
                      Delete my account
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg={6}>
            <div className="card_small">
              <div className="head">
                <h2>Posts</h2>
              </div>
              <Row className="pt-4">
                {userDetails?.post?.map((x, index) => {
                  return (
                    <Col lg={4} className="mb-3">
                      <img
                        // alt={`Image ${index}`}
                        onClick={() => openLightbox(index)}
                        // onClick={() => setToggler(!toggler)}
                        className="profile-add-img"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "200px",
                          margin: "5px",
                          cursor: "pointer",
                        }}
                        src={`${FILE_URL}${x?.image}`}
                        // src={`http://192.168.0.88:4018/${x?.image}`}
                        // src={`https://spotlifts-api.bosselt.com/${x?.image}`}
                      />
                    </Col>
                  );
                })}
                {userDetails?.post?.length == 0 && "No Data Available Yet"}
                {selectedImage !== null && (
                  <div className="lightbox">
                    <span className="close" onClick={closeLightbox}>
                      &times;
                    </span>
                    <img
                      src={`${FILE_URL}${userDetails?.post[selectedImage]?.image}`}
                      // alt={`Image ${selectedImage}`}
                      style={{ maxWidth: "100%", maxHeight: "80vh" }}
                    />
                  </div>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Body>
          <p className="modal_head">
            Are you sure you want to Activate/Deactivate this account?
          </p>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <Button variant="secondary" onClick={handleDeactivate}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={delShow} onHide={() => setDelShow(false)} animation={false}>
        <Modal.Body>
          <p className="modal_head">
            Are you sure you want to Delete this account?
          </p>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <Button variant="secondary" onClick={handleAccDel}>
            Yes
          </Button>
          <Button variant="primary" onClick={() => setDelShow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default UserDetails;
