import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Card,
  Container,
  Row,
  Form,
  Button,
  Table,
  Stack,
  Modal,
  Col,
} from "react-bootstrap";
import { FiSearch, FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotification,
  deleteNotification,
  getNotifications,
} from "../redux/actions/adminActions";
import { Formik } from "formik";
import Pagination from "../components/Pagination";

export default function Notification() {
  const data = useSelector((state) => state.notificationSlice.notifications);
  console.log(data);

  const dispatch = useDispatch();
  const [delShow, setDelShow] = useState(false);
  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const [delId, setDelId] = useState(null);
  const [search, setsearch] = useState(null);
  const [pages, setpages] = useState(1);
  const [limit, setlimit] = useState(10);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const notifications = useSelector(
    (state) => state.notificationSlice.notifications
  );

  useEffect(() => {
    dispatch(
      getNotifications({
        page: pages,
        limit: limit,
        search: search ? search : "",
      })
    );
  }, [flag, search, pages]);

  const toObj = {
    0: "All Female",
    1: "All Male",
    2: "All Others",
    3: "Everyone",
  };

  const handleDelete = () => {
    dispatch(deleteNotification(delId)).then((res) => {
      console.log(res);
      if (res?.payload?.success) {
        setDelShow(false);
        setFlag(!flag);
      }
    });
  };

  const handlesearch = (e) => {
    setsearch(e.target.value);
  };

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <div className="d-flex justify-content-end mb-4 theme mt-5">
              <Button  size="lg" onClick={handleShow}>
                <FiPlus /> Add
              </Button>
            </div>
            <Card className="border-0 p-4 rounded-card user-section notify-sec ">
              <Stack
                className="user-table-align"
                direction="horizontal"
                gap={3}
              >
                <div className="p-2">
                  <h3>Notification</h3>
                </div>
                <div className="p-2 ms-auto">
                  <div className="position-relative m-2 align-items-center">
                    <Form.Control
                      type="search"
                      placeholder="Search.."
                      className="serch-feild"
                      name="search"
                      onChange={handlesearch}
                    />
                   {!search && <FiSearch className="searchicon2" />}
                  </div>
                </div>
              </Stack>
              <Table
                responsive
                variant=""
                className="border-0 custom-table mt-3"
              >
                <thead className="first-td">
                  <tr>
                    <th>S.No</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>To</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.notifications?.map((not, i) => {
                    const seralnumber = (pages - 1) * limit + i + 1;
                    return (
                      <tr>
                        <td>{seralnumber}</td>
                        <td>{not?.title}</td>
                        <td>{not?.desc}</td>
                        <td>{toObj[not?.to]}</td>
                        <td>
                          <Stack
                            direction="horizontal"
                            className="table-btn"
                            gap={3}
                          >
                            <Button
                              onClick={() => {
                                setDelShow(true);
                                setDelId(not?._id);
                              }}
                              variant="secondary"
                            >
                              Delete
                            </Button>
                          </Stack>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {data?.data?.notifications?.length == 0 && (
                <div className="no-data-updated">
                  <p className="no-data">No Notification Yet</p>
                </div>
              )}
            </Card>
          </Row>
        </Container>

        <Pagination
          totalstuff={data?.data?.totalUsers}
          limit={data?.data?.limit}
          setpages={setpages}
          search={search}
        />
      </Layout>

      {/* Notify Model */}
      <Modal show={show} onHide={handleClose} className="notify-model">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className=" border-0">
          <Modal.Title className="text-center mb-5">
            Add Notification
          </Modal.Title>

          <Formik
            initialValues={{ title: "", desc: "", to: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.title) {
                errors.title = "Please enter title";
              }
              if (!values.desc) {
                errors.desc = "Description is required";
              }
              if (!values.to) {
                errors.to = "Select user";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(addNotification(values)).then((res) => {
                console.log(res);
                if (res?.payload?.success) {
                  setFlag(!flag);
                  handleClose();
                }
              });

              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit} className="notify-details">
                <Form.Select
                  aria-label="Default select example"
                  className="mb-3"
                  name="to"
                  onChange={handleChange}
                >
                  <option hidden={true}>Select User</option>
                  <option value={0}>All Female</option>
                  <option value={1}>All Male </option>
                  <option value={2}>Others</option>
                  <option value={3}>Everyone</option>
                </Form.Select>
                <span className="formik-errors">
                  {errors.to && touched.to && errors.to}
                </span>

                <Form.Group controlId="formGridEmail" className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    type="text"
                    placeholder="Title"
                  />
                  <span className="formik-errors">
                    {errors.title && touched.title && errors.title}
                  </span>
                </Form.Group>
                <Form.Group className="mb-4" controlId="formGridAddress2">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="desc"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.desc}
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                  />
                  <span className="formik-errors">
                    {errors.desc && touched.desc && errors.desc}
                  </span>
                </Form.Group>
                <Form.Group
                  className="mb-2 model-btn text-center notify-modal-btn"
                  controlId="formGridAddress2"
                >
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    size="lg"
                  >
                    Notify
                  </Button>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}

      {delId && (
        <Modal show={delShow} onHide={() => setDelShow(false)}>
          <Modal.Body>
            <p className="modal_head">
              Are you sure you want to delete this notification?
            </p>
          </Modal.Body>
          <Modal.Footer className="m-auto">
            <Button variant="secondary" onClick={handleDelete}>
              Yes
            </Button>
            <Button variant="primary" onClick={() => setDelShow(false)}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
