import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addNotification,
  deleteNotification,
  getNotifications,
} from "../actions/adminActions";

const initialState = {
  notifications: null,
  
};

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
           
           state.notifications = payload
        // if (payload.success) {
        //   state.notifications = payload.data;
        // } elspayload?.message);
        // }e if (!payload.success) {
        //   toast.error(
      })
      .addCase(addNotification.fulfilled, (state, { payload }) => {
        // console.log(payload)
        if (payload.success) {
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteNotification.fulfilled, (state, { payload }) => {
        if (payload.success) {
          // state.notifications = state.notifications?.filter(
          //   (not) => not?._id != payload.data?._id
          // );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default notificationSlice.reducer;
