import Login from "./pages/Login";
import User from "./pages/User";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import CoachDetail from "./components/user/CoachDetail";
import UserVerifications from "./pages/UserVerifications";
import Report from "./pages/Report";
import Notification from "./pages/Notification";
import ChangePassword from "./pages/ChangePassword";
import Excercises from "./pages/Excercises";
import SelfReflection from "./pages/SelfReflection";
import AddNewReflection from "./pages/AddNewReflection";
import QuestionDetails from "./pages/QuestionDetails";
import PrivateRoute from "./routes/PrivateRoute";
import Courses from "./pages/Courses";
import UserDetails from "./pages/UserDetails";
import EditSelfReflection from "./pages/EditSelfReflection";
import Support from "./pages/Support";
import SupportChat from "./pages/SupportChat";
import ProfileView from "./pages/ProfileView";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/profile-view/:id" element={<ProfileView />} />
        <Route path="/*" element={<Navigate to="/" />} />

        <Route path="admin/*" element={<Navigate to="/admin/dashboard" />} />
        <Route path="/admin" element={<PrivateRoute />}>
          <Route path="users" element={<User />} />
          <Route path="userdetails/:id" element={<UserDetails />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="coachdetail/:id" element={<CoachDetail />} />
          <Route path="user-verifications" element={<UserVerifications />} />
          <Route path="report" element={<Report />} />
          <Route path="notification" element={<Notification />} />
          <Route path="excercises" element={<Excercises />} />
          <Route path="courses/:id/:name" element={<Courses />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="self-reflection" element={<SelfReflection />} />
          <Route path="add-new-reflection" element={<AddNewReflection />} />
          <Route path="support" element={<Support />} />
          <Route path="support-chat" element={<SupportChat />} />

          <Route
            path="editSelfReflection/:id"
            element={<EditSelfReflection />}
          />
          <Route path="question-details" element={<QuestionDetails />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
