import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Container,
  Row,
  Card,
  Button,
  Col,
  Table,
  Modal,
  Form,
  DropdownButton,
  Dropdown,
  Stack,
} from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import dummy from "../images/dummy_img.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addexercise,
  deleteWorkout,
  editWorkout,
  workOutList,
} from "../redux/actions/adminActions";
import { toast } from "react-toastify";
import { CleanHands, CleaningServices } from "@mui/icons-material";
import Pagination from "../components/Pagination";

export default function Excercises() {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const list = useSelector((state) => state.exercisedata.exerciseList);
  console.log(list);
  let number=0
  const [showCat, setShowCat] = useState(false);
  const [flag, setFlag] = useState(false);
  const [pages, setPages] = useState(1);
  const [search, setSearch] = useState("");
  const [showCourse, setShowCourse] = useState(false);
  const [limit, setlimit] = useState("10");
  const [prevImg, setPrevImg] = useState();
  const [workOutId, setWorkoutId] = useState("");
  const [FocusedMuscle, setFocusedMuscle] = useState("");
  const [FocusedMuscleerr, setFocusedMuscleerr] = useState("");
  const [addExercise, setAddExercise] = useState({
    name: "",
    type: "",
    description: "",
    muscles_Targeted: "",
    image: "",
  });
  const [addExerciseerr, setAddExerciseerr] = useState({
    nameerr: "",
    typeerr: "",
    descriptionerr: "",
    muscles_Targetederr: "",
    imageerr: "",
  });

  console.log(workOutId, "id");

  const dispatch = useDispatch();

  const handlechange = (e) => {
    setAddExercise((old) => ({ ...old, [e.target.name]: e.target.value }));
    setAddExerciseerr((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };

  const handleSubmit = () => {
    validation();
  };
  const handledata = (e) => {
    setFocusedMuscle(e.target.value);
    setFocusedMuscleerr("");
  };

  const handleImgUpload = (e) => {
    setAddExercise((old) => ({ ...old, image: e.target.files[0] }));
    setPrevImg(URL.createObjectURL(e.target.files[0]));
    setAddExerciseerr((old) => ({ ...old, imageerr: "" }));
  };

  useEffect(() => {
    // let data =FocusedMuscle?.split(",") ;

    // setAddExercise((old) => ({ ...old, muscles_Targeted: data }));
    if (typeof FocusedMuscle === "string") {
      let data = FocusedMuscle.length > 0 ? FocusedMuscle.split(",") : null;
      setAddExercise((old) => ({ ...old, muscles_Targeted: data }));
    }
  }, [FocusedMuscle]);

  const validation = () => {
    let isvalid = true;
    let err = {
      nameerr: "",
      typeerr: "",
      descriptionerr: "",
      muscles_Targetederr: [],
      imageerr: "",
    };

    if (!addExercise?.name) {
      err.nameerr = "Please enter title";
      isvalid = false;
    }
    if (!addExercise?.type) {
      err.typeerr = "Please select category";
      isvalid = false;
    }
    if (!addExercise?.description) {
      err.descriptionerr = "Please enter description";
      isvalid = false;
    }
    if (!FocusedMuscle) {
      setFocusedMuscleerr("Please enter muscle targeted");
      isvalid = false;
    }
    if (!prevImg) {
      err.imageerr = "Please upload the image";
      isvalid = false;
    }
    if (!isvalid) {
      setAddExerciseerr(err);
    }
    if (isvalid) {
      const data = new FormData();

      data.append("image", addExercise?.image);
      data.append("name", addExercise?.name);
      data.append("type", addExercise?.type);
      data.append("description", addExercise?.description);
      data.append("muscles_Targeted", addExercise?.muscles_Targeted);

      dispatch(addexercise(data)).then((res) => {
        if (res?.payload?.success) {
          setFlag(!flag);
          setShowCat(false);
          setAddExercise({
            name: "",
            type: "",
            description: "",
            muscles_Targeted: "",
            image: "",
          });
          setFocusedMuscle("");
          setPrevImg("");
        }
      });
    }
  };

  useEffect(() => {
    dispatch(workOutList({ pages: pages, search: search, limit: limit }));
  }, [flag,search,pages]);

  const handleedit = (id, name, desc, muscles_Targeted, image, type) => {
    console.log(muscles_Targeted, "target");
    setAddExercise({
      name: name ? name : "",
      type: type,
      description: desc,
      muscles_Targeted: muscles_Targeted,
      
    });
    setShowCourse(true);
    setPrevImg(`https://spotlifts-api.bosselt.com/${image}`);
    setFocusedMuscle(muscles_Targeted?.join(""));
    setWorkoutId(id);
  };

  console.log(prevImg,"image")

  const handleUpdate = () => {
    let isvalid = true;
    let err = {
      nameerr: "",
      typeerr: "",
      descriptionerr: "",
      muscles_Targetederr: [],
      imageerr: "",
    };

    if (!addExercise?.name) {
      err.nameerr = "Please enter title";
      isvalid = false;
    }
    if (!addExercise?.type) {
      err.typeerr = "Please select category";
      isvalid = false;
    }
    if (!addExercise?.description) {
      err.descriptionerr = "Please enter description";
      isvalid = false;
    }
    if (!FocusedMuscle) {
      setFocusedMuscleerr("Please enter muscle targeted");
      isvalid = false;
    }
    if (!prevImg) {
      err.imageerr = "Please upload the image";
      isvalid = false;
    }
    if (!isvalid) {
      setAddExerciseerr(err);
    }
    if (isvalid) {
      const data = new FormData();
      data.append("id", workOutId);
      data.append("image", addExercise?.image);
      data.append("name", addExercise?.name);
      data.append("type", addExercise?.type);
      data.append("description", addExercise?.description);
      data.append("muscles_Targeted", addExercise?.muscles_Targeted);

      dispatch(editWorkout(data)).then((res) => {
        if (res?.payload?.success) {
          toast.success(res?.payload?.message)
          setFlag(!flag);
          setShowCourse(false);
          setAddExercise({
            name: "",
            type: "",
            description: "",
            muscles_Targeted: "",
            
          });
          setFocusedMuscle("");
          setPrevImg("");
        }
      });
    
    }
  };

  console.log(addExercise?.image)

  const handledelete = (id) => {
    console.log(id);
    dispatch(deleteWorkout({ id: id })).then((res) => {
      if (res?.payload?.success) {
        toast.success(res?.payload?.message);
        setFlag(!flag);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  console.log(list?.data?.ExersiceArr)

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <Stack className="mb-3 mt-4" direction="horizontal" gap={1}>
              {/* <div className="p-2 ms-auto"><Button className="text-primary-clr" size="lg" variant=""><FiFilter/></Button>{' '}</div> */}
              <div className="p-2 ms-auto">
                <Button
                  className="text-primary-clr align-item-center table-head-btn"
                  size="lg"
                  variant="light"
                  onClick={() => setShowCat(true)}
                >
                  Add
                </Button>
              </div>
            </Stack>
            <Card className="border-0 card-user-detail rounded-card meditation-info px-4">
              <div className="user-table-align table-head hstack gap-3">
                <div>
                  <h2>Exercises</h2>
                </div>
                <div className="p-2 ms-auto">
                  <div className="position-relative m-2">
                    <Form.Control
                      type="search"
                      placeholder="Search.."
                      className="serch-feild"
                      onChange={(e) => setSearch(e.target.value)}
                      name="search"
                    />
                     {!search && <FiSearch className="searchicon2" />}
                  </div>
                </div>
              </div>
              <Card className="border-0 p-4 rounded-card user-section ">
                <Table
                  responsive
                  variant=""
                  className="border-0 custom-table mt-3"
                >
                  <thead className="first-td">
                    <tr>
                      <th>S.No.</th>
                      <th>Category</th>
                      <th>Exercise Name</th>
                      <th>Total Sets</th>
                      <th>Total Repitions</th>
                      <th>Description</th>
                      <th>Image</th>
                      <th>Muscle Targeted</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list?.data?.ExersiceArr?.map((x,i) => {
                     
                      return x?.Exercise?.map((item, index) => {
                        {/* setNumber(number+1) */}
                      
                        
                        return (
                          <>
                            <tr>
                              <td>{number+=1}.</td>
                              <td>{x?.ExerciseName || "N/A"}</td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleedit(
                                    item?._id,
                                    item?.name,
                                    item?.description,
                                    item?.muscles_Targeted,
                                    item?.image,
                                    item?.type
                                  );
                                }}
                              >
                                {item?.name || "N/A"}
                              </td>

                              <td>3</td>
                              <td>3*12</td>
                              <td className="disc-text">
                                <p>{item?.description || "N/A"}</p>
                              </td>
                              <td>
                                <img
                                  src={
                                    item?.image
                                      ? ` ${BASE_URL + "/" + item?.image}`
                                      : dummy
                                  }
                                  alt="user image"
                                  className="rounded-circle profile-image"
                                />
                              </td>
                              <td className="disc-text">
                                <p>
                                  {" "}
                                  {item?.muscles_Targeted?.map((i) => i) ||
                                    "N/A"}
                                </p>
                              </td>
                              <td>
                                {/* <DropdownButton
                                  id="dropdown-item-button "
                                  className="border-0 secondary-drop-area"
                                  title="Delete"
                                  onClick={() => handledelete(item?._id)}
                                >
                                  Delete
                                </DropdownButton> */}
                                
                            <Button
                               onClick={() => handledelete(item?._id)}
                              as="a"
                              variant="secondary"
                            >
                             Delete
                            </Button>
                              </td>
                            </tr>
                          </>
                        );
                      });
                    })}
                    
                  </tbody>
                </Table>
                {list?.data?.ExersiceArr?.length == 0 && (
                      
                      <div className="no-data-updated">
                        <p className="no-data">No data updated yet</p>
                      </div>
                    
                )}
              </Card>
            </Card>
          </Row>
        </Container>

        <Pagination
          totalstuff={list?.data?.total}
          limit={list?.data?.limit}
          setpages={setPages}
          search={search}
        />
      </Layout>

      {/* Course Category Model */}
      <Modal
        show={showCat}
        onHide={() => {
          setShowCat(false);
          setAddExercise({
            name: "",
            type: "",
            description: "",
            muscles_Targeted: "",
            image: "",
          });
          setFocusedMuscle("");
          setPrevImg("");
          setAddExerciseerr("")
          setFocusedMuscleerr("")

          // setCatTitle();
          // setImage();
          // setPrevImg();
        }}
        className="text-center meditation-model modal-content-80"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
        <Modal.Title className="mb-4 px-3">Add Exercise Cards</Modal.Title>
        <Modal.Body>
          <div className="mb-4 text-start">
            <Form.Label>Add Category</Form.Label>
            <Form.Select
              name="type"
              value={addExercise.type}
              onChange={handlechange}
              aria-label="Default select example"
            >
              <option>Select Workout</option>
              <option value="1">Chest Workout</option>
              <option value="2">Back Workout</option>
              <option value="3">Leg Workout</option>
              <option value="4">Shoulder Workout</option>
              <option value="5">Bieceps Workout</option>
              <option value="6">Triceps Workout</option>
            </Form.Select>
            <Form.Label className="text-danger">
              {addExerciseerr?.typeerr}
            </Form.Label>
          </div>
          <Form.Group
            className="mb-3 text-start"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Add Exercise Card Title</Form.Label>
            <Form.Control
              name="name"
              value={addExercise.name}
              onChange={handlechange}
              type="email"
              placeholder="Add Title..."
            />
            <Form.Label className="text-danger">
              {addExerciseerr?.nameerr}
            </Form.Label>
          </Form.Group>
          <Form.Group
            className="mb-3 text-start"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Description</Form.Label>
            <Form.Control
              name="description"
              value={addExercise.description}
              onChange={handlechange}
              type="email"
              placeholder="Add Title..."
            />
            <Form.Label className="text-danger">
              {addExerciseerr?.descriptionerr}
            </Form.Label>
          </Form.Group>
          <Form.Group
            className="mb-3 text-start"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Add Sets</Form.Label>
            <Form.Control value="3" type="email" placeholder="Add Title..." />
          </Form.Group>
          <Form.Group
            className="mb-3 text-start"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Add Reps</Form.Label>
            <Form.Control
              value="12*3"
              type="email"
              placeholder="Add Title..."
            />
          </Form.Group>
          <Form.Group
            className="mb-3 text-start"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Focused Muscles</Form.Label>
            <Form.Control
              name="FocusedMuscle"
              value={FocusedMuscle}
              onChange={handledata}
              type="email"
              placeholder="Add Title..."
            />
            <Form.Label className="text-danger">{FocusedMuscleerr}</Form.Label>
          </Form.Group>
        </Modal.Body>

        <Form.Group className="mb-4 px-3" controlId="formGridAddress2">
          <div className="upld-place-click">
            <p>Upload Image</p>
            <Form.Control
              className="add-upld-hide add-icon-hide"
              type="file"
              placeholder="click to upload audio file"
              onChange={handleImgUpload}
              // value={addExercise?.image}
              name="image"
            />
            <Form.Label className="text-danger">
              {addExerciseerr?.imageerr}
            </Form.Label>
          </div>
        </Form.Group>
        <div className="img-upld-place add-image-upld">
          {prevImg && <img src={prevImg} />}
          {/* <Form.Control
            className="add-image-upld-hide-icon"
            type="file"
            placeholder="click to upload audio file"
            // onChange={handleImgUpload}
          /> */}
        </div>
        <Modal.Footer className="m-auto border-0">
          <Button
            className="mb-5"
            variant="primary"
            size="lg"
            onClick={handleSubmit}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      

      <Modal
        show={showCourse}
        onHide={() => {
          setShowCourse(false);
          setAddExercise({
            name: "",
            type: "",
            description: "",
            muscles_Targeted: "",
            image: "",
          });
          setFocusedMuscle("");
          setPrevImg("");
          setAddExerciseerr("")
          setFocusedMuscleerr("")

          // setCatTitle();
          // setImage();
          // setPrevImg();
        }}
        className="text-center meditation-model modal-content-80"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg" 
      >
        <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
        <Modal.Title className="mb-4 px-3">Edit Exercise Cards</Modal.Title>
        <Modal.Body>
          <div className="mb-4 text-start">
            <Form.Label>Add Category</Form.Label>
            <Form.Select
              name="type"
              value={addExercise.type}
              onChange={handlechange}
              aria-label="Default select example"
            >
              <option>Select Workout</option>
              <option value="1">Chest Workout</option>
              <option value="2">Back Workout</option>
              <option value="3">Leg Workout</option>
              <option value="4">Shoulder Workout</option>
              <option value="5">Bieceps Workout</option>
              <option value="6">Triceps Workout</option>
            </Form.Select>
            <Form.Label className="text-danger">
              {addExerciseerr?.typeerr}
            </Form.Label>
          </div>
          <Form.Group
            className="mb-3 text-start"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Add Exercise Card Title</Form.Label>
            <Form.Control
              name="name"
              value={addExercise.name}
              onChange={handlechange}
              type="email"
              placeholder="Add Title..."
            />
            <Form.Label className="text-danger">
              {addExerciseerr?.nameerr}
            </Form.Label>
          </Form.Group>
          <Form.Group
            className="mb-3 text-start"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Description</Form.Label>
            <Form.Control
              name="description"
              value={addExercise.description}
              onChange={handlechange}
              type="email"
              placeholder="Add Title..."
            />
            <Form.Label className="text-danger">
              {addExerciseerr?.descriptionerr}
            </Form.Label>
          </Form.Group>
          <Form.Group
            className="mb-3 text-start"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Add Sets</Form.Label>
            <Form.Control value="3" type="email" placeholder="Add Title..." />
          </Form.Group>
          <Form.Group
            className="mb-3 text-start"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Add Reps</Form.Label>
            <Form.Control
              value="12*3"
              type="email"
              placeholder="Add Title..."
            />
          </Form.Group>
          <Form.Group
            className="mb-3 text-start"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Focused Muscles</Form.Label>
            <Form.Control
              name="FocusedMuscle"
              value={FocusedMuscle}
              onChange={handledata}
              type="email"
              placeholder="Add Title..."
            />
            <Form.Label className="text-danger">{FocusedMuscleerr}</Form.Label>
          </Form.Group>
        </Modal.Body>

        <Form.Group className="mb-4 px-3" controlId="formGridAddress2">
          <div className="upld-place-click">
            <p>Upload Image</p>
            <Form.Control
              className="add-upld-hide add-icon-hide"
              type="file"
              placeholder="click to upload audio file"
              onChange={handleImgUpload}
              // value={addExercise?.image}
              name="image"
            />
            <Form.Label className="text-danger">
              {addExerciseerr?.imageerr}
            </Form.Label>
          </div>
        </Form.Group>
         <div className="img-upld-place add-image-upld">
          {prevImg && <img src={prevImg} />}
          <Form.Control
            className="add-image-upld-hide-icon"
            type="file"
            placeholder="click to upload audio file"
            // onChange={handleImgUpload}
          />
        </div>
       
        <Modal.Footer className="m-auto border-0">
          <Button
            className="mb-5"
            variant="primary"
            size="lg"
            onClick={handleUpdate}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
