import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { deleteCourse, editCourse, getCourses, shareLink } from "../actions/adminActions";
import { convertLength } from "@mui/material/styles/cssUtils";

const initialState = {
  courses: null,
};

export const courseSlice = createSlice({
  name: "courseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(shareLink.fulfilled, (state, { payload }) => {
        console.log(payload)
        if (payload.success) {
          state.courses = payload.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      
  },
});

export default courseSlice.reducer;
